import {
  IBot,
  IUserMessage,
  IAssistantMessage,
  IBotContext,
  IUserMessageInstrution,
  IPromptAttachment,
  IChatMessageType,
  IAssistantArtifact,
} from "@gratico/sdk";

// Function to generate a unique message ID
const generateId = () => crypto.randomUUID();

// Function to create a single UserMessage by iterating over the bots and generating attachments
export const createUserMessage = async (
  userId: string,
  bots: IBot[],
  userPrompt: string,
  context: IBotContext
): Promise<IUserMessage> => {
  // Collect attachments from all bots

  const attachments: Array<IPromptAttachment> = (
    await Promise.all(
      bots.map(async (bot) => {
        const attachments = await bot.attachments(context, {}, userPrompt);
        return attachments;
      })
    )
  ).flat();

  const instructions: Array<IUserMessageInstrution> = (
    await Promise.all(
      bots.map(async (bot) => {
        const instructions = await bot.instructions(
          context,
          {},
          userPrompt,
          attachments
        );
        return instructions.map((el) => {
          return {
            bot: bot.id,
            content: el,
          };
        });
      })
    )
  ).flat();

  // Create a single UserMessage
  const userMessage: IUserMessage = {
    id: generateId(),
    type: IChatMessageType.USER,
    createdAt: Date.now(),
    userId: userId,
    instructions,
    content: userPrompt,
    attachments: attachments,
    meta: {}, // additional metadata if needed
  };

  return userMessage;
};

// Function to process the output and create a single AssistantMessage by iterating over the bots and generating artifacts
export const createAssistantMessage = async (
  bots: IBot[],
  llmOutput: string,
  context: IBotContext
): Promise<IAssistantMessage> => {
  // Collect artifacts from all bots

  const botOutputs = await Promise.all(
    bots.map(async (bot) => {
      const botOutput = await bot.output.processor(context, llmOutput);
      return botOutput;
    })
  );
  const artifacts: Array<IAssistantArtifact> = botOutputs
    .map((el) => el.artifacts)
    .flat();

  // Create a single AssistantMessage
  const assistantMessage: IAssistantMessage = {
    id: generateId(),
    type: IChatMessageType.ASSISTANT,
    createdAt: Date.now(),
    model: bots.map((bot) => bot.id).join(", "), // Optionally aggregate bot ids
    content: llmOutput, // Final output from the assistant
    artifacts: artifacts,
    meta: {}, // additional metadata if needed
  };

  return assistantMessage;
};

export const persistMessage = async (text: string): Promise<void> => {};
