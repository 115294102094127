/** @jsx h **/

import {
  IActiveBuffer,
  IBufferRouteParams,
  IBufferType,
  IDiagnostics,
  ILoggedinSession,
  IProjectChangedEventPaylod,
} from "@gratico/kernel";
import {
  BufferRoutePattern,
  IHydraState,
  RefRoutePattern,
  ShellProps,
} from "@gratico/sdk";
import { debounce } from "@gratico/stdlib";
import { component, Fragment, h, produce, Signal } from "alfama";
import { extractParameters, Route, RouterContext, Switch } from "alfama-router";
import { HeaderIsland } from "../../components/HeaderIsland";
import { NavIsland } from "../../components/NavIsland";
import { TabsIsland } from "../../components/TabsIsland";
import {
  BuffersContext,
  KernelConnection,
  KernelProvider,
  ModalsAPIContext,
} from "../../providers/index";
import { setTitle } from "../../utils";
import { ActionsIsland } from "../ActionsIsland";
import { OverviewBar } from "../OverviewBar";
import { BootedLayoutInner } from "./BootedLayoutInner";
import { BufferRoute } from "./BufferRoute";
import { Home } from "./Home";
import { RefRoute } from "./RefRoute";
import { ActionBar } from "../ActionBar";

export const BootedLayout = component<{
  $connection: Signal<KernelConnection>;
  $session: Signal<ILoggedinSession>;
}>(
  "BootedLayout",
  (
    props,
    { wire, setContext, getContext, onMount, onUnmount, signal, store }
  ) => {
    const { kernel, master, viewport } = props.$connection.get();
    const $buffers = getContext(BuffersContext);
    const modalsAPI = getContext(ModalsAPIContext);

    const $diagnostics = store<IDiagnostics>("diagnostics", {
      diagnostics: [],
    });

    // set up data
    const $activeRef = signal<string | undefined>("activeRef", undefined);
    const $chatExpanded = signal("expanded", false);
    const $activePanel = signal<string | undefined>("active", undefined);

    const $activeBuffer = signal<IActiveBuffer>("activeBuffer", {});
    const session = props.$session.get();

    // title
    setTitle(`${session.project.name}`);

    const _projectChangedHandler = async (data: IProjectChangedEventPaylod) => {
      console.log("projectChanged", data);
      const activeRoute = $activeRoute.get();
      if (data.projectId === activeRoute?.projectId) {
        produce($diagnostics, (s) => (s.loading = true));
        const diagnostics = await master.getLSPDiagnostics(
          data.projectId,
          data.ref
        );
        if (diagnostics) {
          console.log("diagnostics", diagnostics);
          produce($diagnostics, (s) => {
            s.loading = false;
            s.diagnostics = diagnostics;
          });
        } else {
          console.log("diagnostics", diagnostics);
          produce($diagnostics, (s) => {
            s.loading = false;
          });
        }
      }
    };
    const projectChangedHandler = debounce(_projectChangedHandler, 600);
    onMount(() => {
      kernel.pubsub.emitter.on("projectChanged", projectChangedHandler);
    });
    onUnmount(() => {
      kernel.pubsub.emitter.off("projectChanged", projectChangedHandler);
    });

    const router = getContext(RouterContext).get();
    const $activeRoute = signal<Partial<IBufferRouteParams> | undefined>(
      "route",
      undefined
    );
    const routeHandler = () => {
      const params = extractParameters(
        BufferRoutePattern,
        router.location.pathname
      );
      if (params) params.type = IBufferType.FILE;
      $activeRoute.set(params);
    };
    onMount(() => {
      router.addEventListener("popstate", routeHandler);
      routeHandler();
    });
    onUnmount(() => {
      router.removeEventListener("popstate", routeHandler);
    });
    const $hydra = signal<IHydraState>("hydraState", {
      keyMap: [],
    });
    const childProps: ShellProps = {
      ...props,
      kernel,
      master,
      session,
      $activeRef: $activeRef,
      $buffers,
      $activeBuffer,
      $activePanel,
      $diagnostics,
      $expanded: $chatExpanded,
      $hydra,
      modals: modalsAPI,
      showInput(cmd) {
        console.log("cmd", cmd);
        modalsAPI.closeModal();
        modalsAPI.showModel(
          <ActionBar
            {...childProps}
            command={cmd}
            onClose={() => {
              modalsAPI.closeModal();
            }}
          />
        );
      },
    };
    return (
      <KernelProvider viewport={viewport} master={master} kernel={kernel}>
        <Fragment>
          <BootedLayoutInner {...childProps} />
          <Switch>
            <Route key="home" path="~" component={Home} />
            <Route
              key="buffer"
              path={BufferRoutePattern}
              component={BufferRoute}
            />
            <Route key="ref" path={RefRoutePattern} component={RefRoute} />
          </Switch>
          <TabsIsland {...childProps} />
          <NavIsland {...childProps} />
          <OverviewBar {...childProps} />
          <HeaderIsland {...childProps} />
          <ActionsIsland {...childProps} />
        </Fragment>
      </KernelProvider>
    );
  }
);
