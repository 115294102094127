import { createRuntime } from "@gratico/packager";
import { IViewportKernel, TS } from "@gratico/sdk";
import { getCheckoutId } from "../git/index";
import pathe from "pathe";
import { transformTypescript } from "./utils/logger";
import { basename } from "pathe";

export async function createCheckoutRuntimeViewport(
  kernel: IViewportKernel,
  projectId: string,
  ref: string
) {
  const checkoutId = getCheckoutId(kernel, projectId, ref);
  const runtime = await createRuntime(
    kernel,
    {
      [globalThis.location.origin]: {
        fetcher: async (url: string, options: any) => {
          let file = await kernel.master.runtimeFetchFile(checkoutId, url);
          const extname = pathe.extname(url);
          if (extname.match(/.ts?/)) {
            const filename = basename(url);
            const ts = (await kernel.importLibrary<TS>("typescript")) as TS;
            const transformed = transformTypescript(
              ts,
              filename,
              file.text,
              []
            );
            file.text = transformed;
          }
          return file;
        },
        resolver: async (
          importName: string,
          parentUrl: string,
          defaultResolve: Function
        ) => {
          if (importName[0] !== ".")
            return defaultResolve(importName, parentUrl);
          const resolved = await kernel.master.runtimeResolveFile(
            checkoutId,
            importName,
            parentUrl
          );
          return resolved;
        },
      },
    },
    {}
  );
  const { importMap, pkgs } = await kernel.master.runtimeFetchImportMap(
    projectId,
    ref
  );
  runtime.pkgs = pkgs;
  runtime.import.addImportMap(importMap);
  kernel.runtimes.set(checkoutId, runtime);
  return runtime;
}
