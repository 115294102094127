import { IBufferRouteParams, IBufferType } from "./types/objects";

export function getBufferIdFromRouterParams(
  params: IBufferRouteParams
): string {
  if (params.type == IBufferType.FILE) {
    return `${params.type}:${params.projectId}:${params.ref}:${params.path}`;
  } else {
    return `${params.type}:${params.projectId}:${params.ref}`;
  }
}

export const RefRoutePattern = `~/:projectId/:ref*/~/`;
export const getRefRouteURL = (params: { projectId: string; ref: string }) => {
  return `/~/${params.projectId}/${params.ref}/~/`;
};

export const BufferRoutePattern = `~/:projectId/:ref*/~/:appName/:path*`;

export const getBufferRouteURL = (
  params:
    | {
        type: IBufferType.FILE;
        projectId: string;
        ref: string;
        appName: string;
        path: string;
      }
    | { type: IBufferType.REF; projectId: string; ref: string }
) => {
  if (params.type === IBufferType.FILE) {
    return `/~/${params.projectId}/${params.ref}/~/${params.appName}${params.path}`;
  } else {
    return `/~/${params.projectId}/${params.ref}/~/`;
  }
};
