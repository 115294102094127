import { HydraKeyMap, HydraKeyMapType, IEditorAction } from "@gratico/sdk";
import { pullGitCommand } from "@gratico/kernel";

export const gitKeyMap: HydraKeyMap[] = [
  {
    id: "git",
    parentId: "leader",
    type: HydraKeyMapType.GROUP,
    key: "g",
    name: "Git",
  },
  {
    id: "pull",
    parentId: "git",
    type: HydraKeyMapType.ACTION,
    key: "p",
    name: "Pull",
    action: pullGitCommand,
  },
];
