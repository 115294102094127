import pathe from "pathe";
import { IBuffer, IBufferRouteParams, IBufferType } from "./types/objects";
import { getFileIcon } from "./icons";

export const getBufferTitle = (buffer: IBuffer) => {
  if (buffer.type === IBufferType.FILE) {
    const path = buffer.path;
    return pathe.basename(path);
  } else {
    return buffer.ref;
  }
};
