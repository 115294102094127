/** @jsx h **/
import {
  IBranchChanges,
  IMasterKernelAPI,
  IViewportKernel,
} from "@gratico/sdk";
import { Fragment, When, component, h } from "alfama";
import { getCurrentProjectParameters } from "../../constants";
import { SVGLoader } from "../Layout/Loaders/index";
import { CommitForm, FilesList, FilePreview } from "./Changes";

export const Changes = component<{
  master: IMasterKernelAPI;
  kernel: IViewportKernel;
  onClose: Function;
}>("Changes", (props, { getContext, signal, onMount }) => {
  const { master, kernel } = props;
  const $changes = signal<IBranchChanges | undefined>("changes", undefined);
  const $selectedFile = signal<undefined | string>("selectedFile", undefined);
  const $selectedFiles = signal<string[]>("selectedFiles", []);
  const checkoutParamters = getCurrentProjectParameters(kernel, location);

  onMount(async () => {
    const changes = await master.getChanges(
      checkoutParamters.projectId,
      checkoutParamters.ref
    );
    console.log("changes", changes);
    $changes.set(changes);
    $selectedFiles.set(changes.map((el) => el.path));
    if (changes.length > 0) {
      $selectedFile.set(changes[0].path);
    }
  });

  return (
    <Fragment>
      <div
        style="border: 1px solid var(--component-border-color);  box-shadow: var(--component-box-shadow);"
        class="modal-box w-[100%] max-w-[1000px] layered-bg p-0"
      >
        <div
          style="max-height: calc(100vh - 100px);"
          class="flex flex-col h-[100vh] overflow-hidden"
        >
          <div class="flex flex-col flex-1 p-0 overflow-hidden">
            <ChangesHeader />

            <div
              style="height: 100%;"
              class="flex overflow-hidden  flex-1 border-b "
            >
              <div class="flex flex-1 flex-col w-1/3 border-r overflow-hidden">
                <When
                  condition={($) => !!$changes.get($)}
                  views={{
                    true: () => (
                      <Fragment>
                        <FilesList
                          checkoutParamters={checkoutParamters}
                          $selectedFile={$selectedFile}
                          $selectedFiles={$selectedFiles}
                          {...props}
                          changes={$changes.get() as IBranchChanges}
                        />
                        <CommitForm
                          checkoutParamters={checkoutParamters}
                          $selectedFile={$selectedFile}
                          $selectedFiles={$selectedFiles}
                          {...props}
                          changes={$changes.get() as IBranchChanges}
                          onCommit={() => {
                            props.onClose();
                          }}
                        />
                      </Fragment>
                    ),
                    false: () => null,
                  }}
                ></When>
              </div>
              <div
                style="background: var(--component-bg-color);"
                class="w-2/3 rounded flex justify-center items-center"
              >
                <When
                  condition={($) => !!$changes.get($)}
                  views={{
                    true: () => (
                      <FilePreview
                        {...props}
                        checkoutParamters={checkoutParamters}
                        $selectedFile={$selectedFile}
                        $selectedFiles={$selectedFiles}
                        changes={$changes.get() as IBranchChanges}
                      />
                    ),
                    false: () => (
                      <div class="full-h full-w flex items-center justify-center flex-1">
                        <SVGLoader />
                      </div>
                    ),
                  }}
                ></When>
              </div>
            </div>
          </div>
        </div>
      </div>
      <form method="dialog" class="modal-backdrop">
        <button>close</button>
      </form>
    </Fragment>
  );
});

export const ChangesHeader = component("ChangesHeader", () => {
  return (
    <div
      style="background: var(--component-bg-color);"
      class="flex justify-between items-center px-2 py-2 border-b"
    >
      <div class="flex items-center space-x-4">
        <div class="flex items-center text-2xl">
          <span class="mr-2">
            <i class="ri-git-branch-line"></i>
          </span>
          <button class=" px-2 py-1 rounded flex items-center">master</button>
        </div>
      </div>
      <div class="flex flex-col items-right">
        <button class="button  py-0 px-3  text-1xl">
          <span class="mr-1">Fetch origin</span>
          <i class="ri-refresh-line text-2xl"></i>
        </button>
      </div>
    </div>
  );
});
