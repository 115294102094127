/** @jsx h **/

import { ILoggedinSession } from "@gratico/sdk";
import { component, h, Signal } from "alfama";

export function getGitHost() {
  const envName = import.meta.env.VITE_ENV;
  const host = (function () {
    if (envName === "development") {
      return `http://localhost:4500`;
    } else if (envName === "preview") {
      return `https://git.grati.co`;
    } else {
      return `https://git.grati.co`;
    }
  })();
  return host;
}

export const GitInfoMenu = component<{ $session: Signal<ILoggedinSession> }>(
  "GitInfoMenu",
  (props, { signal, getContext }) => {
    return (
      <div style="width: 500px" class={` p-0`}>
        <h4 class="py-2 text-2xl">
          <i class="ri-git-repository-line"></i> git clone with HTTPS
        </h4>
        <div>
          <div class="flex flex-row justify-center content-center py-2">
            <div
              class="flex-none justify-center content-center px-2 "
              data-tip="copy git URL"
              style="cursor: pointer;"
              onClick={() => {
                var copyText = document.getElementById(
                  "gratico-git-url-input"
                ) as HTMLInputElement;

                // Select the text field
                copyText.select();
                copyText.setSelectionRange(0, 99999); // For mobile devices

                // Copy the text inside the text field
                navigator.clipboard.writeText(copyText.value);
              }}
            >
              <i class="ri-clipboard-line"></i>
            </div>
            <div class="flex-1 form-control">
              <input
                id="gratico-git-url-input"
                class="input input-bordered input-sm  "
                type="text"
                readOnly={true}
                value={`${getGitHost()}/git/${
                  props.$session.get().project.id
                }.git`}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);
