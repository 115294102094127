/** @jsx h **/

import { component, Fragment, h, Signal, VElement, When } from "alfama";

export const Dropdown = component<{
  icon: string;
  position?: string[];
  renderBody?: () => VElement;
}>("Dropdown", (props, utils) => {
  return (
    <div
      class={`dropdown ${
        props.position
          ? props.position.map((p) => ` dropdown-${p} `).join(" ")
          : " "
      }`}
    >
      <div
        tabIndex={0}
        role="button"
        class=" flex justify-center items-center px-2"
      >
        <i class={props.icon}></i>
      </div>
      <ul
        tabIndex={0}
        class="dropdown-content menu bg-base-100 rounded-box z-[1] border"
      >
        {props.renderBody ? (
          props.renderBody()
        ) : (
          <li>
            <a>SOON</a>
          </li>
        )}
      </ul>
    </div>
  );
});
