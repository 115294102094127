/** @jsx h **/

import { getBufferIcon, getBufferTitle } from "@gratico/kernel";
import { component, Fragment, h } from "alfama";
import hotkeys from "hotkeys-js";

import { BuffersContext, ModalsAPIContext } from "../../providers/index";

import { ShellProps } from "../../@types";
import { setTitle } from "../../utils";
import { BuffersRenderer } from "./BuffersRenderer";
import { startHydra } from "../../utils/hydra";
import { keyMap } from "../../utils/hydra/keyMap";

hotkeys.filter = function (e) {
  var tag = (e.target || e.srcElement) as HTMLElement;
  //console.log("t", tag);
  const isEditable =
    tag &&
    (/^(INPUT|TEXTAREA|SELECT)$/.test((tag as HTMLElement).tagName) ||
      tag.isContentEditable);
  if (isEditable) {
    if (e.key === "Escape") {
      // tag.blur();
      return false;
    }
    // console.log("input", tag, e.code);
    return false;
  }
  return true;
};

export const BootedLayoutInner = component<ShellProps>(
  "BootedLayoutInner",
  (props, { onMount, getContext, onUnmount, wire }) => {
    const $buffers = getContext(BuffersContext);
    const { kernel, $activePanel, master } = props;
    const modalsAPI = getContext(ModalsAPIContext);
    wire(async ($) => {
      const buffers = $($buffers);
      const activeBuffer =
        buffers.activeBufferId &&
        buffers.list.find((el) => el.id === buffers.activeBufferId);
      if (activeBuffer) {
        const baseName = getBufferTitle(activeBuffer);
        setTitle(`${baseName}`);
        const icon = await getBufferIcon(activeBuffer);
        document
          .querySelector(`head link[rel="icon"]`)!
          .setAttribute("href", icon);
      }
    }).run(); //

    const hydra = startHydra(props, keyMap);
    onMount(() => {
      wire(($) => {
        const hydra = props.$hydra.get($);
        if (hydra.keyMap.length > 0) {
          props.$activePanel.set("hydra");
        } else {
          props.$activePanel.set(undefined);
        }
      }).run();
    });
    onUnmount(() => {
      hydra();
    });

    //    onMount(() => {
    //      hotkeys("ctrl+shift+p, command+shift+p", (e) => {
    //        try {
    //          modalsAPI.closeModal();
    //          modalsAPI.showModel(
    //            <CommandBar
    //              master={master}
    //              kernel={kernel}
    //              onClose={() => {
    //                modalsAPI.closeModal();
    //              }}
    //            />
    //          );
    //        } catch (e) {
    //          console.error(e);
    //        }
    //      });
    //      hotkeys("ctrl+k, command+k", function (e) {
    //        $activePanel.set("chat");
    //        e.preventDefault();
    //        e.stopPropagation();
    //      });
    //      hotkeys("esc", (e) => {
    //        //console.log("esc");
    //        $activePanel.set(undefined);
    //        modalsAPI.closeModal();
    //      });
    //      hotkeys("space", (e) => {
    //        //console.log("space");
    //        $activePanel.set("hydra");
    //      });
    //    });
    //    onUnmount(() => {
    //      hotkeys.unbind("ctrl+k, command+k");
    //      hotkeys.unbind("ctrl+shift+p, command+shift+p");
    //      hotkeys.unbind("esc");
    //      hotkeys.unbind("space");
    //    });
    return (
      <Fragment>
        <BuffersRenderer {...props} session={props.$session.get()} />
        <div
          id="hydra-overlay"
          class="glossy-bg fixed bottom-0 left-0 w-full flex justify-between items-center mb-1"
          style="z-index: 2147483647; max-height: 300px;"
        />
      </Fragment>
    );
  }
);
