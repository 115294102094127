export default {};
import type { TransformationContext, SourceFile, Node } from "typescript";
type TS = typeof import("typescript");

// Function to add line location to console.log statements
export function loggerTransformer(ts: TS, context: TransformationContext) {
  return (sourceFile: SourceFile) => {
    function visit(node: Node): Node {
      if (
        ts.isCallExpression(node) &&
        ts.isPropertyAccessExpression(node.expression) &&
        node.expression.expression.getText() === "console" &&
        node.expression.name.getText() === "log"
      ) {
        const lineNumber =
          sourceFile.getLineAndCharacterOfPosition(node.getStart()).line + 1;

        const lineNumberNode = ts.factory.createStringLiteral(
          `graticoLogger ${lineNumber}:`
        );

        // Create a new argument list with the line number prepended
        const newArguments = [lineNumberNode, ...node.arguments];

        // Create a new call expression with the updated arguments
        return ts.factory.updateCallExpression(
          node,
          node.expression,
          node.typeArguments,
          newArguments
        );
      }
      return ts.visitEachChild(node, visit, context);
    }
    return ts.visitNode(sourceFile, visit);
  };
}

// Function to read, transform, and write the TypeScript file
export function transformTypescript(
  ts: TS,
  fileName: string,
  sourceCode: string,
  transformers: any[]
) {
  const sourceFile = ts.createSourceFile(
    fileName,
    sourceCode,
    ts.ScriptTarget.Latest,
    true
  );
  const result = ts.transform(sourceFile, transformers);
  const printer = ts.createPrinter();
  const transformedSourceFile = result.transformed[0];

  const transformedCode = printer.printFile(
    transformedSourceFile as SourceFile
  );
  return transformedCode;
}
