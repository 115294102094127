/** @jsx h **/
import { component, produce, reify, h } from "alfama";
import {
  IBufferRouteParams,
  IBufferType,
  getBufferIdFromRouterParams,
} from "@gratico/kernel";
import { getFileIcon } from "@gratico/sdk";
import { ParentRouteContext } from "alfama-router";
import { KernelContext } from "../../providers/index";
import { BuffersContext } from "../../providers/index";

export const BufferRoute = component(
  "BufferRoute",
  (props, { getContext, onMount, wire }) => {
    const { kernel, master } = getContext(KernelContext).get();
    const route = getContext(ParentRouteContext).get();
    const $buffers = getContext(BuffersContext);
    console.log("route", route);
    onMount(async () => {
      console.log("route", route);
      if (
        !route ||
        !route.params.projectId ||
        !route.params.ref ||
        !route.params.path
      ) {
        console.error("no params", route?.params, route);
        return;
      }
      // todo: look into how to make path parsing typesafe the way hono does
      // important: this isn't typesafe
      const bufferParams: IBufferRouteParams = {
        appName: route.params.appName,
        projectId: route.params.projectId,
        ref: route.params.ref,
        path: "/" + route.params.path,
        type: IBufferType.FILE,
      };
      const bufferId =
        route.params.appName + ":" + getBufferIdFromRouterParams(bufferParams);
      console.log("bufferParams", bufferParams, bufferId);
      const existing = reify($buffers.list).find((el) => el.id === bufferId);
      console.log("existing", existing);
      if (existing) {
        produce($buffers, (s) => {
          s.activeBufferId = bufferId;
        });
      } else {
        try {
          const icon = await getFileIcon(
            {
              path: bufferParams.path,
            },
            {}
          );
          const tempBuffer = {
            ...bufferParams,
            ref: bufferParams.ref,
            id: bufferId,
            icon,
            createdAt: Date.now(),
          };
          // console.log("tempBuffer", tempBuffer);
          produce($buffers, (s) => {
            s.list.push(tempBuffer);
            s.activeBufferId = tempBuffer.id;
          });
        } catch (e) {
          // if fails due to git conflict in lock file
          console.error(e);
        }
      }
    });
    return null;
  }
);
