/** @jsx h **/

import { component, Fragment, h } from "alfama";

export type MarketProps = {
  onClose: Function;
};
export const Market = component<MarketProps>(
  "FileProperty",
  (props, { signal, wire }) => {
    const $name = signal("name", "");

    return (
      <Fragment>
        <div
          style="border: 1px solid var(--component-border-color);  box-shadow: var(--component-box-shadow);"
          class="modal-box w-[100%] max-w-[1000px] layered-bg"
        >
          <div class=" p-1 rounded-lg shadow-lg">
            <div class="text-6xl text-center  px-1 py-4">
              Extend with plugins <i class="ri-shapes-line "></i>
            </div>
            <div class="mb-4">
              <input
                type="text"
                placeholder="Search Market or Install from NPM"
                class="w-full p-2 border rounded-lg"
              />
            </div>

            <div style="display: none;" class="flex space-x-4 mb-6">
              <button class="text-gray-100">All</button>
              <button class="text-gray-300 hover:text-gray-500">
                Whiteboard
              </button>
              <button class="text-gray-300 hover:text-gray-500">Editor</button>
            </div>

            <h2 class="text-xl font-bold mb-4">Featured</h2>

            <div class="grid grid-cols-2 gap-4">
              {[
                {
                  name: "Uno CSS",
                  author: "gratico",
                },
                {
                  name: "Unify UI",
                  author: "gratico",
                },
              ].map((plugin, index) => (
                <div key={index} class="border rounded-lg p-4">
                  <h3 class="font-semibold">{plugin.name}</h3>
                  <p class="text-sm text-gray-600">By {plugin.author}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <form method="dialog" class="modal-backdrop">
          <button>close</button>
        </form>
      </Fragment>
    );
  }
);
