import { HydraKeyMap, HydraKeyMapType, IEditorAction } from "@gratico/sdk";
import { createFunctionCodeCommand } from "@gratico/kernel";

export const codeKeyMap: HydraKeyMap[] = [
  {
    id: "code",
    parentId: "leader",
    type: HydraKeyMapType.GROUP,
    key: "c",
    name: "Code",
  },
  {
    id: "createFunction",
    parentId: "code",
    type: HydraKeyMapType.ACTION,
    key: "f",
    name: "Create Function",
    action: createFunctionCodeCommand,
  },
];
