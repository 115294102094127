/** @jsx h **/

import {
  getBufferIcon,
  getBufferRouteURL,
  getBufferTitle,
} from "@gratico/kernel";
import { component, Each, h, reify } from "alfama";
import { Link, RouterContext } from "alfama-router";
import { ShellProps } from "../../@types";
import { createSortable } from "./utilts";

///type A = ExtractElement<{ [id: string]: IWorkingCopy }>

export const Tabs = component<ShellProps>(
  "Tabs",
  (props, { wire, signal, onMount, getContext, onUnmount }) => {
    const router = getContext(RouterContext).get();
    const routerHandler = () => {
      //console.log("route changed");
    };
    let cleanup: any;
    onMount(() => {
      if (container) {
        // cleanup = createSortable(container.parentElement as HTMLElement);
      }
      router.addEventListener("popstate", routerHandler);
    });
    onUnmount(() => {
      if (cleanup) cleanup();
      router.removeEventListener("popstate", routerHandler);
    });
    let container: HTMLElement;
    console.log("route changed", JSON.stringify(reify(props.$buffers)));
    return (
      <div>
        <div
          role="tablist"
          style="scrollbar-width: none;"
          class="flex h-full w-full overflow-x-auto"
          ref={(el) => (container = el)}
        >
          <Each
            cursor={props.$buffers.list}
            renderItem={(cursor) => {
              const buffer = reify(cursor());
              console.log("buffer", buffer);
              return (
                <div
                  role="tab"
                  style={wire(($): string => {
                    const active = $(props.$buffers.activeBufferId);
                    return active === buffer.id
                      ? "background: #ffffff08; border-color: dodgerblue;"
                      : "";
                  })}
                  class={
                    "flex px-2 py-2 border-b-2 border-transparent glossy-bg"
                  }
                >
                  <div
                    style="width: 20px"
                    class={"flex items-center justify-center"}
                    onClick={(e) => {}}
                  >
                    <img
                      data-fileicon="true"
                      style="width: 20px"
                      src={getBufferIcon(buffer)}
                    />
                  </div>
                  <Link
                    class="flex items-center justify-center pl-2 truncate text-sm"
                    href={getBufferRouteURL(buffer)}
                  >
                    {getBufferTitle(buffer)}
                  </Link>
                  {false && <i class="ri-asterisk text-sm"></i>}
                </div>
              );
            }}
          ></Each>
        </div>
      </div>
    );
  }
);

export const TabsIsland = component<ShellProps>(
  "TabsIsland",
  (props, { signal, store, onMount, getContext, onUnmount }) => {
    return (
      <div
        style="height: 40px; z-index: 214748364; left: 80px; right: 80px;"
        class={
          "flex layered-bg absolute top-0 left-8 right-8 border rounded-r rounded-l border-t-0"
        }
      >
        <div
          key="tabs"
          style="scrollbar-width: none;"
          class="flex flex-1 overflow-x-auto"
        >
          <Tabs
            {...props}
            $activeRef={props.$activeRef}
            $buffers={props.$buffers}
          />
        </div>
        <div class="pl-2 py-2 flex justify-center items-center">
          <div class="flex text-sm px-1">
            <div
              style="background: #222; border: 1px solid var(--component-border-color);"
              class="bg-white text-white mr-1 px-1 rounded uppercase"
            >
              SPC
            </div>
            <span class="mx-1">
              <i class="ri-keyboard-box-fill"></i>
            </span>
          </div>
          <div class="flex text-sm px-1 hidden">
            <div
              style="background: #222; border: 1px solid var(--component-border-color);"
              class="bg-white text-white mr-1 px-2 rounded"
            >
              ⌘ + K
            </div>
            <span class="mx-1">OPEN FILE</span>
          </div>
        </div>
      </div>
    );
  }
);
