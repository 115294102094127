import { IPubSub, IPubSubDebugOptions } from "@gratico/sdk";
import Emittery from "emittery";

const getTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}.${now.getMilliseconds()}`;
};

const logMessage = (
  pubsub: IPubSub,
  type: string,
  color: string,
  topic: string,
  data: any
) => {
  const logTime = getTime();
  console.groupCollapsed(
    `%c ${type} `,
    `background: ${color}; color: black; font-family:monospace;`,
    topic,
    logTime
  );
  console.log(data);
  console.groupEnd();
};

const createPromise = (map: Map<string, any>, id: string) => {
  return new Promise((resolve, reject) => {
    map.set(id, { resolve, reject });
  });
};

const handlePromiseResolution = (
  promiseMap: Map<string, any>,
  id: string,
  payload: any
) => {
  const promise = promiseMap.get(id);
  if (promise) {
    const { err, resp } = payload || {};
    if (err) {
      promise.reject(err);
    } else {
      promise.resolve(resp);
    }
    promiseMap.delete(id);
  }
};
const handler = async (pubsub: IPubSub, e: MessageEvent) => {
  const {
    emitter,
    localChannel,
    pendingMessages,
    requestHandlers,
    pendingRequests,
  } = pubsub;
  const { type, payload, to, from } = e.data;
  if (type === "pubsub") {
    emitter.emitSerial(payload.topic, payload.data);
    localChannel.postMessage({
      type: "pubsubAck",
      payload: { id: payload.id, topic: payload.topic },
      from: pubsub.id,
    });
    pubsub.debug.receive &&
      logMessage(pubsub, "◀ rcv ✉", "darkseagreen", payload.topic, payload);
  } else if (type === "pubsubAck") {
    const promise = pendingMessages.get(e.data.id);
    if (promise) {
      const { err, resp } = e.data.payload || {};
      if (resp) {
        promise.resolve(resp);
      } else {
        promise.reject(err);
      }
    }
  } else if (type === "request") {
    const handler = requestHandlers.get(payload.topic);
    if (handler) {
      const replyId = crypto.randomUUID();
      try {
        const resp = await handler(payload.data);
        localChannel.postMessage({
          id: replyId,
          type: "reply",
          payload: { replyTo: payload.id, topic: payload.topic, resp },
          from: pubsub.id,
          to: from,
        });
      } catch (e: any) {
        localChannel.postMessage({
          type: "reply",
          id: replyId,
          payload: {
            topic: payload.topic,
            err: e.toString(),
            replyTo: payload.id,
          },
          from: pubsub.id,
          to: from,
        });
      }
      pubsub.debug.process &&
        logMessage(
          pubsub,
          "▶ processing request",
          "gray",
          payload.topic,
          payload
        );
    }
  } else if (type === "reply" && to === pubsub.id) {
    handlePromiseResolution(pendingRequests, payload.replyTo, payload);
    pubsub.debug.reply &&
      logMessage(
        pubsub,
        "◀ reply",
        payload.err ? "indianred" : "green",
        payload.topic,
        payload
      );
  }
};

export function setupGossipChannel(
  m: string,
  localChannel: BroadcastChannel,
  debugOptions?: IPubSubDebugOptions
): IPubSub {
  const requestHandlers = new Map();
  const pendingMessages = new Map();
  const pendingRequests = new Map();
  const emitter = new Emittery({});
  const debug = debugOptions || {};
  const memberId = m; //+ "/" + crypto.randomUUID();
  const pubsub: IPubSub = {
    id: memberId,
    debug,
    localChannel,
    emitter,
    handler: handler as any,
    pendingMessages,
    pendingRequests,
    requestHandlers,

    request(topic, data) {
      const id = crypto.randomUUID();
      localChannel.postMessage({
        type: "request",
        payload: { topic, data, id },
        from: memberId,
      });
      debug.request &&
        logMessage(pubsub as IPubSub, "▶ request", "skyblue", topic, {
          id,
          topic,
          data,
        });
      return createPromise(pendingRequests, id) as Promise<any>;
    },

    reply(topic, fn) {
      requestHandlers.set(topic, fn);
    },

    publish(topic, data) {
      const id = crypto.randomUUID();
      localChannel.postMessage({
        type: "pubsub",
        payload: { topic, data, id },
        from: memberId,
      });
      debug.publish &&
        logMessage(pubsub as IPubSub, "▶ pub ✉", "powderblue", topic, {
          id,
          topic,
          data,
        });
      return createPromise(pendingMessages, id);
    },
    destroy: () =>
      localChannel.removeEventListener(
        "message",
        pubsub.handler as unknown as EventListenerOrEventListenerObject
      ),
  };
  // todo: why is the typecase necessary?
  pubsub.handler = handler.bind(
    null,
    pubsub as IPubSub
  ) as unknown as EventListenerOrEventListenerObject;
  localChannel.addEventListener("message", pubsub.handler);
  return pubsub as IPubSub;
}
