/** @jsx h **/

import {
  component,
  Each,
  Fragment,
  h,
  Signal,
  StoreCursor,
  When,
} from "alfama";

import { IAlfamaProvider } from "@gratico/kernel";
import { BanyanProps, TreeNode } from "../../types";
import { TreeNodeItem } from "../TreeItem";

export { IModeTypes } from "../../utils/index";

export const Tree = component<
  BanyanProps & {
    cursor: () => StoreCursor<TreeNode>;
    root?: boolean;
    $expanded: Signal<boolean>;
    treeProvider: IAlfamaProvider;
  }
>("Tree", (props, { wire, getContext, onMount }) => {
  const { cursor } = props;

  return (
    <Fragment>
      <When
        condition={($) => {
          return !!$(props.$expanded);
        }}
        views={{
          true: () => {
            return (
              <Each
                cursor={props.cursor().children}
                renderItem={(el, i) => {
                  return <TreeNodeItem {...props} key="true" cursor={el} />;
                }}
              ></Each>
            );
          },
          false: () => {
            return null;
          },
        }}
      ></When>{" "}
    </Fragment>
  );
});
