//export { Buffer } from "buffer";
export class Buffer {
  data: Uint8Array;

  constructor(sizeOrArray: number | Uint8Array | string) {
    if (typeof sizeOrArray === "number") {
      this.data = new Uint8Array(sizeOrArray);
    } else if (typeof sizeOrArray === "string") {
      this.data = new Uint8Array(new TextEncoder().encode(sizeOrArray));
    } else {
      this.data = sizeOrArray;
    }
  }

  static from(input: string | Uint8Array, encoding?: string): Buffer {
    if (typeof input === "string") {
      return new Buffer(new TextEncoder().encode(input));
    }
    return new Buffer(input);
  }

  static alloc(size: number): Buffer {
    return new Buffer(size);
  }

  toString(encoding: string = "utf8"): string {
    if (encoding !== "utf8") {
      throw new Error("Unsupported encoding");
    }
    return new TextDecoder().decode(this.data);
  }

  slice(start?: number, end?: number): Buffer {
    return new Buffer(this.data.slice(start, end));
  }

  set(array: Uint8Array, offset?: number): void {
    this.data.set(array, offset);
  }

  get length(): number {
    return this.data.length;
  }
  static concat(buffers: Buffer[], totalLength?: number): Buffer {
    if (
      totalLength === null ||
      totalLength === undefined ||
      !Number.isFinite(totalLength)
    ) {
      totalLength = buffers.reduce(
        (acc, buffer) => acc + buffer.data.length,
        0
      );
    }

    const result = new Uint8Array(totalLength);
    let offset = 0;

    for (const buffer of buffers) {
      result.set(buffer.data, offset);
      offset += buffer.length;
    }

    return new Buffer(result);
  }

  [Symbol.iterator](): Iterator<number> {
    let index = 0;
    const data = this.data;
    return {
      next: () => ({
        value: data[index++],
        done: index > data.length,
      }),
    };
  }
}
