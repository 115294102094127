let libraryCache: {
  [name: string]: {
    url: string;
    module: any;
  };
} = {
  typescript: {
    url: "https://cdn.jsdelivr.net/npm/typescript@5.5.2/+esm",
    module: undefined,
  },
  exami: {
    url: "https://cdn.jsdelivr.net/npm/exami@0.8.52/+esm",
    module: undefined,
  },
  "lowlight/index": {
    url: "https://cdn.jsdelivr.net/npm/lowlight@3.1.0/lib/index.js/+esm",
    module: undefined,
  },
  "lowlight/common": {
    url: "https://cdn.jsdelivr.net/npm/lowlight@3.1.0/lib/common.js/+esm",
    module: undefined,
  },
  "highlight.js/typescript": {
    url: "https://cdn.jsdelivr.net/npm/highlight.js@11.10.0/lib/languages/typescript.js/+esm",
    module: undefined,
  },
  prettier: {
    url: "https://cdn.jsdelivr.net/npm/prettier@3.3.3/standalone.mjs",
    module: undefined,
  },
  "prettier/estree": {
    url: "https://cdn.jsdelivr.net/npm/prettier@3.3.3/plugins/estree.mjs",
    module: undefined,
  },
  "prettier/typescript": {
    url: "https://cdn.jsdelivr.net/npm/prettier@3.3.3/plugins/typescript.mjs",
    module: undefined,
  },
};
export const importLibrary = async (name: string) => {
  if (libraryCache[name].module) {
    return libraryCache[name].module;
  } else {
    const mod = await import(libraryCache[name].url);
    libraryCache[name].module = mod;
    return mod;
  }
};
