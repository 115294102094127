import { JSONSchema4 } from "json-schema";
import { IMasterKernel, IViewportKernel } from "./kernel";

export enum IPromptAttachmentType {
  "FILE" = "FILE",
}
export type IPromptFileAttachment = {
  type: IPromptAttachmentType.FILE;
  attrs: { path: string; contents: string };
};
export type IPromptAttachment = IPromptFileAttachment;

export enum IAssistantArtifactType {
  "FILE" = "FILE",
}
export type IAssistantFileArtifact = {
  type: IAssistantArtifactType.FILE;
  attrs: { path: string; contents: string };
};
export type IAssistantArtifact = IAssistantFileArtifact;

export type IBotContext = {
  kernel: IViewportKernel;
  master: IMasterKernel;
  projectId: string;
  ref: string;
};
export type IBotConfig<T = JSONSchema4> = {
  schema: T;
};

export type IBot<ConfigSchema = JSONSchema4, ConfigValue = any> = {
  id: string;
  trigger: string;
  config: IBotConfig;
  attachments: (
    context: IBotContext,
    config: ConfigValue,
    prompt: string
  ) => Promise<IPromptAttachment[]>;
  instructions: (
    context: IBotContext,
    config: ConfigValue,
    prompt: string,
    attachments: IPromptAttachment[]
  ) => Promise<string[]>;
  output: {
    processor: (context: IBotContext, str: string) => Promise<IBotOutput>;
  };
};

export type IBotOutput = {
  artifacts: IAssistantArtifact[];
};

export enum IChatMessageType {
  SYSTEM = "SYSTEM",
  USER = "USER",
  ASSISTANT = "ASSISTANT",
}

export type IBaseMessage = {
  id: string;
  type: IChatMessageType;
  createdAt: number;
};

export interface ISystemMessage extends IBaseMessage {
  type: IChatMessageType.SYSTEM;
  content: string;
}

export type IUserMessageInstrution = {
  bot: string;
  content: string;
};
export interface IUserMessage extends IBaseMessage {
  type: IChatMessageType.USER;
  userId: string;
  instructions: IUserMessageInstrution[];
  content: string;
  attachments: IPromptAttachment[];
  meta: any;
}

export interface IAssistantMessage extends IBaseMessage {
  type: IChatMessageType.ASSISTANT;
  model: string;
  content: string;
  artifacts: IAssistantArtifact[];
  meta: any;
}

export type IChatMessage = ISystemMessage | IUserMessage | IAssistantMessage;
