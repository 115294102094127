/** @jsx h **/
import { h, render, RenderContext, unrender } from "alfama";
//(globalThis as any).process = { env: {} };
import { Layout } from "./pages/Layout/index";
import swURL from "./sw?worker&url";
import "../../../packages/styling/framework/src/main.css";

let ctx: RenderContext | undefined;

let renderApp = async (props: { Layout: typeof Layout }) => {
  const { Layout } = props;
  const el = <Layout />;
  console.time("render");
  ctx = render(el, document.getElementById("app")!);
  console.timeEnd("render");
};

const registerServiceWorker = async () => {
  console.info("swURL", swURL);
  if ("serviceWorker" in navigator) {
    return navigator.serviceWorker
      .register(swURL, {
        type: "module",
        scope: "/",
      })
      .then((registration) => {
        console.info(
          "Service Worker registered with scope:",
          registration.scope
        );
      })
      .catch((error) => {
        console.error("Service Worker registration failed:", error);
      });
  }
};

window.addEventListener("load", async () => {
  try {
    await registerServiceWorker();
  } catch (e) {
    console.error(e);
  }
  await renderApp({ Layout });
});

if (import.meta.hot) {
  import.meta.hot.on("vite:beforeFullReload", () => {
    console.log("prevented beforeFullReload");
    throw new Error("prevented");
  });

  import.meta.hot.accept("./pages/Layout/index", (newModule) => {
    //window.location.reload();
    console.log("rerendering app");
    if (newModule && ctx) {
      //window.location.reload();
      unrender(ctx);
      renderApp(newModule as unknown as { Layout: typeof Layout });
    }
  });
}
