import { IViewportKernel } from "@gratico/sdk";
import { ES_MODULE_SHIM_URL } from "@gratico/packager";

const getRuntimeJS = (mods: string[]) => {
  return `
globalThis.boot = async function (esmsInitOptions) {
  globalThis.esmsInitOptions = esmsInitOptions
  await import("${ES_MODULE_SHIM_URL}");
  const importShim = globalThis.importShim;
  return {importShim, modules: ${JSON.stringify(mods)} };
}
`;
};

export async function getPreviewHTML(
  kernel: IViewportKernel,
  params: { projectId: string; ref: string }
) {
  const resp = await kernel.master.getPreviewHTML(
    params.projectId,
    params.ref,
    "/"
  );
  const transformed = await tranformPreviewHTML(kernel, resp);
  return transformed;
}

export async function tranformPreviewHTML(
  kernel: IViewportKernel,
  html: string
) {
  // Step 1: Parse the HTML string into a DOM object
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  // Step 2: Collect all existing script tags and their src attributes
  const scriptTags = doc.querySelectorAll("script");
  const scriptSrcList: string[] = [];

  scriptTags.forEach((script) => {
    // If the script has a src attribute, collect it
    const src = script.getAttribute("src");
    const type = script.getAttribute("type");
    if (src && type === "module" && src[0] == ".") {
      scriptSrcList.push(src);
      script.remove();
    }
  });

  const script = doc.createElement("script");
  script.textContent = getRuntimeJS(scriptSrcList);
  script.type = "module";
  doc.body.appendChild(script); // Add script to the body

  const serializer = new XMLSerializer();
  const modifiedHTMLString = serializer.serializeToString(doc);

  return modifiedHTMLString;
}
