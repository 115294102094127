/** @jsx h **/

import { component, h } from "alfama";

import { bc } from "../../utils/boot";
import imageURL from "../../images/escher/circle-limit.svg?url";
import { SVGLoader } from "../../components/Layout/Loaders";

export const UnbootedLayout = component<{}>(
  "UnbootedLayout",
  (props, { signal, wire, setContext, onMount, onUnmount }) => {
    const handler = (e: MessageEvent) => {
      const $el = document.querySelector(".boot-message-log")!;
      if (e && e.data) {
        //        console.log("boot", e, $el);
        $el.innerHTML = e.data.message || "&nbsp;";
        if (e.data.type === "booted") {
          bc.removeEventListener("message", handler);
        }
      }
    };
    onMount(() => {
      bc.addEventListener("message", handler);
    });
    onUnmount(() => {
      bc.removeEventListener("message", handler);
    });
    return (
      <div class="h-screen w-screen flex flex-col justify-center items-center">
        <img class="border rounded glossy-bg hidden" src={imageURL} />
        <div style=" margin: 10px auto;">
          <SVGLoader />
        </div>
        <span style="font-size: 24px;" class="boot-message-log">
          loading
        </span>
      </div>
    );
  }
);
