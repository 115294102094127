import { ASTKind } from "./kinds";
export { ASTKind as ASTKind } from "./kinds";

// Transformations
// IValue -> IValueEvaluation / IValueDefinition
// IType -> ITypeEvaluation / ITypeDefinition

export interface ASTPath {
  parentId?: string;
  siblingScore?: string;
  node: INode;
}

export const ILiteralKinds = [
  ASTKind.IPrimitiveLiteral,
  ASTKind.IFunction,
  ASTKind.ILiteralObject,
  ASTKind.ILiteralArray,
];
export const ITypeLiteralKinds = [
  ASTKind.ITypePrimitiveLiteral,
  ASTKind.ITypeFunction,
  ASTKind.ITypeLiteralObject,
  ASTKind.ITypeLiteralArray,
];
export const IValueKinds = [
  ASTKind.IIdentifier,
  ASTKind.IReference,
  ...ILiteralKinds,
  ASTKind.IValueEvaluation,
];
export const ITypeKinds = [
  ASTKind.IReference,
  ...ITypeLiteralKinds,
  ASTKind.ITypeEvaluation,
];
export const IDefinitionKinds = [
  ASTKind.IValueDefinition,
  ASTKind.ITypeDefinition,
];

export type ILiteral =
  | IPrimitiveLiteral
  | IFunction
  | ILiteralObject
  | ILiteralArray;
export type ITypeLiteral =
  | ITypePrimitiveLiteral
  | ITypeFunction
  | ITypeLiteralObject
  | ITypeLiteralArray;

export type ISimpleValue = IReference | ILiteral | IIdentifier;
export type IValue = ISimpleValue | IValueEvaluation | IMacro;
export type ISimpleType = IReference | ITypeLiteral;
export type IType = ISimpleType | ITypeEvaluation;
export type IDefinition = IValueDefinition | ITypeDefinition;
export type IName = IIdentifier | IObjectPattern | IArrayPattern;

export type IElement = IDefinition | IGroup | IVoid | IAnnotation;

// todo: forgot, what's the use of IMacro?
export interface IMacro<T = any, M = any> extends INode {
  kind: ASTKind.IMacro;
  payload: T;
  metadata?: M;
}

// todo: forgot, what's the use of INative?
export interface INative extends INode {
  kind: ASTKind.INative;
}

export interface IAnnotation extends INode {
  kind: ASTKind.IAnnotation;
  //  anchor: string | [string, string]; // the name of function/variable declaration this annotation is bound to
}

export interface IBlock extends INode {
  kind: ASTKind.IBlock;
}

export interface IVoid extends INode {
  kind: ASTKind.IVoid;
}

export interface IGroup extends INode {
  kind: ASTKind.IGroup;
  defs: IElement[];
}

export interface INode<Metadata extends Record<string, any> = any> {
  id: string;
  kind: ASTKind;
  meta: Metadata;
}

export interface ISourceFile<M extends Record<string, any> = any>
  extends INode<M> {
  kind: ASTKind.ISourceFile;
  defs: IElement[];
  imports: ISourceFileImport[];
}

export interface ISourceFileImportElement extends INode {
  kind: ASTKind.ISourceFileImportElement;
  type: "default" | "normal";
  name: IIdentifier;
  as?: IIdentifier;
}
export interface ISourceFileImport extends INode {
  kind: ASTKind.ISourceFileImport;
  elements: ISourceFileImportElement[];
  path: IPrimitiveLiteral;
}

export interface IIdentifier extends INode {
  kind: ASTKind.IIdentifier;
  name: string;
}
export interface IReference extends INode {
  kind: ASTKind.IReference;
  link: IIdentifier[];
}

export interface IStringLiteral extends INode {
  kind: ASTKind.IStringLiteral;
  value: string;
}
export interface IPrimitiveLiteral extends INode {
  kind: ASTKind.IPrimitiveLiteral;
  value: string | number | boolean | null;
}
export interface ILiteralObject extends INode {
  kind: ASTKind.ILiteralObject;
  keys: Array<IIdentifier | IStringLiteral>;
  value: Array<IValue>;
}
export interface ILiteralArray extends INode {
  kind: ASTKind.ILiteralArray;
  value: Array<IValue>;
}

export interface ITypeLiteralObject extends INode {
  kind: ASTKind.ITypePrimitiveLiteral;
  keys: Array<IIdentifier>;
  value: Array<IType>;
}
export interface ITypeLiteralArray extends INode {
  kind: ASTKind.ITypePrimitiveLiteral;
  value: Array<IType>;
}
export interface ITypePrimitiveLiteral extends INode {
  kind: ASTKind.ITypePrimitiveLiteral;
  type: "string" | "number" | "boolean" | "null" | "function";
  value?: string | number | boolean;
}

export interface IValueEvaluation extends INode {
  kind: ASTKind.IValueEvaluation;
  object: IReference;
  arguments: ISimpleValue[];
}
export interface ITypeEvaluation extends INode {
  kind: ASTKind.ITypeEvaluation;
  object: IReference;
  arguments: ISimpleType[];
}

export interface IValueDefinition extends INode {
  kind: ASTKind.IValueDefinition;
  name: IName;
  value: IValue;
}
export interface ITypeDefinition extends INode {
  kind: ASTKind.ITypeDefinition;
  name: IIdentifier;
  value: IType;
}

export interface IFunction extends INode {
  kind: ASTKind.IFunction;
  parameters: IName[];
  defaultParameters: IValue[];
  parametersType: IType[];
  typeParameters: IType[];
  returnType?: IType;
  value: IDefinition[];
  async?: boolean;
}
export interface ITypeFunction extends INode {
  kind: ASTKind.ITypeFunction;
  params: IIdentifier[];
  paramTypes: IType[];
  returnType: IType;
  async?: boolean;
}

export interface IObjectPattern extends INode {
  kind: ASTKind.IObjectPaterrn;
  patterns: IName[];
}
export interface IArrayPattern extends INode {
  kind: ASTKind.IArrayPaterrn;
  patterns: IName[];
}
