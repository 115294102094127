import { CommandItem, MultiStepItemType } from "@gratico/sdk";

import { bufferCommands } from "./buffers";
import { codeCommands } from "./code";
import { gitCommands } from "./git/index";

export const searchProjectCommand: CommandItem = {
  type: MultiStepItemType.COMMAND,
  id: "searchProject",
  label: "Search Project",
  icon: "ri-menu-search-line",
  action: {
    name: "Search Project",
    inputs: () => undefined,
    async perform(ctx, attrs) {
      console.log("searchProject");
    },
  },
};

export const commands = [
  searchProjectCommand,
  ...codeCommands,
  ...bufferCommands,
  ...gitCommands,
];

export * from "./code";
export * from "./buffers";
export * from "./git/index";
