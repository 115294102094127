import { ObjPathProxy, StoreCursor, Signal } from "alfama/state";
import { IFs } from "@gratico/fs";
import Emittery from "emittery";
import { INode } from "@gratico/ast";
import { IKernel } from "../kernel";

export type KernelConnection = {
  kernel: IViewportKernel;
  master: IMasterKernelAPI;
  viewport: any;
};

export type GithubInstallation = {
  id: number;
  account: { login: string; id: number; avatar_url: string; url: string };
};

export type GithubRepository = {
  id: number;
  name: string;
  full_name: string;
  clone_url: string;
  owner: GithubOwner;
  topics: string[];
  description?: string;
  default_branch: string;
  size: number;
};
export type GithubOwner = {
  login: string;
  id: number;
  avatar_url: string;
  type: "User" | "Organization";
};

export type IRepoChangeType =
  | "MODIFIED"
  | "NEW"
  | "DELETED"
  | "RENAMED"
  | "COPIED"
  | "UPDATED_UNMERGED"
  | "UNTRACKED"
  | "IGNORED"
  | "TYPECHANGE";

export type IRepoChange = {
  path: string;
  type: IRepoChangeType;
};

export type IBranchChange = {
  path: string;
  type?: IRepoChangeType;
  isDirty?: true;
};
export type IBranchChanges = IBranchChange[];

type TS = typeof import("typescript");

import type { LanguageService, LanguageServiceHost, System } from "typescript";
import { IMasterKernelAPI, IViewportKernel } from "../kernel";
import { IPlatformComponentType } from "../platform";

export type EnvState = {
  files: { [path: string]: DocumentRegistryItem & { checksum: string } };
  pluginState: { [id: string]: any };
  projectVersion: number;
};

export type DocumentRegistryItem = {
  contents: string;
  version: string;
  checksum: string;
};

export interface FileIndex {
  imports: {
    importedName: string;
    moduleName: string;
    resolvedPath?: string;
  }[];
  nodes: INode[];
  locals: { name: string; kind: string; isExported: boolean }[];
  usageMap: Record<string, { importName: string; moduleName: string }[]>;
}

export type VirtualTypeScriptEnv = {
  ts: TS;
  fs: IFs;
  sys: System;
  store: EnvState;
  rootFiles: string[];
  fileIndexCache: Record<string, FileIndex>;
  languageService: LanguageService;
  languageServiceHost: LanguageServiceHost;
  addFile: (fileName: string) => void;
  updateFile: (fileName: string, content: string) => void;
  deleteFile: (fileName: string) => void;
  getSourceFile: (fileName: string) => string | undefined;
};

export enum MessageType {
  QUERY_STATE,
  APPLY_UPDATE,
  QUERY_AWARENESS,
  UPDATE_AWARENESS,
}

export type IRealtimeMessage<T = any> = {
  id: string;
  type: MessageType;
  data?: T;
  from?: string;
  to?: string;
  createdAt: number;
};

export interface IRealtimeProvider<Doc = any, Message = any, Awareness = any> {
  id: string;
  docId: string;
  type: string;
  doc: Doc;
  awareness: Awareness;
  getState: Function;
  receiveMessage: (msg: Message) => void;
  sendMessage: (msg: Message) => Promise<void>;
  queryState: Function;
  destroy: Function;
  applyUpdate: Function;
  emitter: Emittery;
  getText(): string;
}

export type IFolderMeta = {
  files: {
    [name: string]: IFileMeta;
  };
};

export type IFileIcon =
  | {
      type: "url";
      value: string;
    }
  | { type: "pack"; value: string; pack?: string };

export type IFileMeta = {
  name: string;
  description: string;
  tags: string[];
  icon?: IFileIcon;
  meta: Record<string, any>;
  timestamps: { created: number; updated: number };
};

export type IProject = {
  id: string;
  userId: string;
  name: string;
  slug?: string;
  defaultBranch: string;
  createdAt: number;
  isPublic: number;
  providerId?: string | null;
  provider?: string | null;
  providerSlug?: string | null;
};

export type IUser = {
  id: string;
  name: string;
  username?: string;
  anon?: boolean;
  avatar?: string;
  emailHash?: string;
};

export type IThread = {
  id: string;
  slug: string;
  name: string;
  userIds: string[];
  userId: string;
  meta: Record<string, any>;
};

export type IThreads = {
  list: IThread[];
};

export enum IBufferType {
  FILE = "file",
  REF = "ref",
}

export type IFileBuferRouteParams = {
  type: IBufferType.FILE;
  appName: string;
  projectId: string;
  ref: string;
  path: string;
};
export type IRefBufferRouteParams = {
  type: IBufferType.REF;
  ref: string;
  projectId: string;
};

export type IBufferRouteParams = IFileBuferRouteParams | IRefBufferRouteParams;

export interface IBufferBase {
  type: IBufferType;
  loaded?: true;
}

export interface IFileBuffer extends IBufferBase {
  id: string;
  icon: string;
  type: IBufferType.FILE;
  appName: string;
  projectId: string;
  ref: string;
  path: string;
  createdAt: number;
  baseChecksum?: string;
  checksum?: string;
  loaded?: true;
}

export interface IRefBuffer extends IBufferBase {
  id: string;
  type: IBufferType.REF;
  projectId: string;
  ref: string;
  createdAt: number;
  baseChecksum?: string;
  checksum?: string;
  loaded?: true;
}

export type IBuffer = IFileBuffer | IRefBuffer;

export type IBuffers = {
  list: IBuffer[];
};

export type IActiveBuffer = {
  activeBufferId?: string;
};

export type IBuffersState = IBuffers & IActiveBuffer;

export type ILoggedoutSession = {
  loggedIn: false;
};

export type IBaseSession = {
  loggedIn: boolean;
  project: IProject;
};

export type ILoggedinSession = {
  loggedIn: true;
  isMember: boolean;
  members: IUser[];
  project: IProject;
  user: IUser;
} & IBaseSession;

export type IUserSession = ILoggedinSession | ILoggedoutSession;

export type IBootParams = {
  session: ILoggedinSession;
};

export interface IViewport {
  id: string;
  params: IBootParams;
  masterId: string;
}

export type IRepoRef = {
  type: "branch";
  value: string;
};

export interface ICheckout<FS = IFs> {
  id: string;
  fs: FS;
  ref: IRepoRef;
  $tree: StoreCursor<TreeNode, TreeNode>;
  env: VirtualTypeScriptEnv;
}

export type TreeNode<T = any> = {
  name: string;
  path: string;
  type: string;
  icon: string;
  meta?: T;
  children: TreeNode[];
  isDirectory: boolean;
  state: { loaded?: boolean };
};

export type IKernelState = { [key: string]: any };

export interface IPeers {
  id: string;
}

export type IProjectChangedEventPaylod = {
  projectId: string;
  ref: string;
  version: number;
};

export type IDiagnostics = {
  loading?: boolean;
  diagnostics: any[];
};

export type IEditorAction<T = any> = {
  name: string;
  description?: string;
  inputs: (currentValue: MultiStepInput[]) => MultiStepInput | undefined;
  perform(props: ShellProps, attrs: any): Promise<T>;
};

export enum MultiStepItemType {
  SYMBOL,
  FILE,
  FOLDER,
  COMMAND,
  STRING,
  NUMBER,
  BOOLEAN,
}

export type SymbolItem = {
  type: MultiStepItemType.SYMBOL;
  value: string;
  meta: { name: string; path: string; kind: string };
};
export type FileItem = {
  type: MultiStepItemType.FILE;
  value: string;
  meta: { name: string; path: string; kind: string };
};
export type FolderItem = {
  type: MultiStepItemType.FOLDER;
  value: string;
  meta: { name: string; path: string; kind: string };
};
export type StringItem = {
  type: MultiStepItemType.STRING;
  value: string;
};
export type NumberItem = {
  type: MultiStepItemType.STRING;
  value: string;
};
export type BooleanItem = {
  type: MultiStepItemType.STRING;
  value: string;
};
export type CommandItem = {
  type: MultiStepItemType.COMMAND;
  id: string;
  label: string;
  icon?: string;
  action: IEditorAction;
};
export type MultiStepPrimitive =
  | SymbolItem
  | FolderItem
  | FileItem
  | NumberItem
  | BooleanItem;
export type MultiStepItem = MultiStepPrimitive | CommandItem;

export type MultiStepInput<T = MultiStepItem> = {
  name: string;
  description?: string;
  type: MultiStepItemType;
  value?: T;
};

export enum HydraKeyMapType {
  GROUP,
  ACTION,
}

export type HydraGroupKeyMap = {
  id: string;
  parentId?: string;
  type: HydraKeyMapType.GROUP;
  key: string;
  name: string;
  description?: string;
};

export type HydraActionKeyMap = {
  id: string;
  parentId?: string;
  type: HydraKeyMapType.ACTION;
  key: string;
  name: string;
  action: CommandItem;
};

export type HydraKeyMap = HydraGroupKeyMap | HydraActionKeyMap;

export type IHydraState = {
  keyMap: HydraKeyMap[];
};

export type ShellProps = {
  $diagnostics: StoreCursor<IDiagnostics>;
  session: ILoggedinSession;
  kernel: IKernel;
  master: IMasterKernelAPI;
  $hydra: Signal<IHydraState>;
  $activeRef: Signal<string | undefined>;
  $connection: Signal<KernelConnection>;
  $session: Signal<ILoggedinSession>;
  $buffers: StoreCursor<IBuffers & IActiveBuffer>;
  $activeBuffer: Signal<IActiveBuffer>;
  $activePanel: Signal<string | undefined>;
  $expanded: Signal<boolean>;
  modals: ModalsAPI;
  showInput: (action: CommandItem) => void;
};

export type ModalsAPI = {
  showModel(el: any): void;
  closeModal(): void;
};
