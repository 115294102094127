/** @jsx h **/

import { component, When, h, Signal } from "alfama";

export const SVGLoader = component<{ class?: string }>("SVGLoader", (props) => {
  return (
    <progress
      style="height: 2px;"
      class={`progress ${props.class || "w-56"}`}
    ></progress>
  );
});
