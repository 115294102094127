import { queue } from "@gratico/stdlib";
import { basename, extname } from "pathe";
import { IBuffer, IBufferType } from "./types";

export const iconBaseURL = "https://cdn.jsdelivr.net/npm/vscode-symbols@0.0.20";

export const getBufferIcon = (buffer: IBuffer) => {
  if (buffer.type === IBufferType.FILE) {
    const path = buffer.path;
    const icon = buffer.icon;
    return buffer.appName === "whiteboard"
      ? `${iconBaseURL}/preview/files/keystatic.png`
      : icon || `data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg"/>`;
  } else {
    return `${iconBaseURL}/preview/folders/folder-github.png`;
  }
};

const cacheMap = new Map();
export const _getIconDefs = async () => {
  const iconDefsURL = `${iconBaseURL}/src/symbol-icon-theme.json`;
  const iconDefs =
    cacheMap.get(iconDefsURL) ||
    (await (async function () {
      const req = await fetch(iconDefsURL);
      const json = await req.json();
      cacheMap.set(iconDefsURL, json);
      return json;
    })());
  return iconDefs;
};
const getIconDefs = queue(_getIconDefs, 1)();

export const getFileIcon = async (
  item: { isDirectory?: boolean; path: string },
  icons: Record<string, string> = {}
) => {
  const iconDefs = await getIconDefs();
  const fileName = basename(item.path);
  const ext = extname(fileName).slice(1);
  //  console.log("ext", ext);
  const icon = (() => {
    if (ext === "space") {
      return { iconPath: icons.whiteboard || "./icons/files/keystatic.png" };
    } else if (item.isDirectory) {
      const iconName = iconDefs.folderNames[fileName] || "folder";
      return iconDefs.iconDefinitions[iconName];
    } else if (ext === "tsx") {
      return {
        iconPath: `${iconBaseURL}/preview/icons/multi/file_type_tsx@3x.png`,
      };
    } else if (ext === "ts") {
      return {
        iconPath: `${iconBaseURL}/preview/icons/multi/file_type_typescript@3x.png`,
      };
    } else if (ext === "js") {
      return {
        iconPath: `${iconBaseURL}/preview/icons/multi/file_type_js@3x.png`,
      };
    } else if (ext === "md") {
      return {
        iconPath: `${iconBaseURL}/preview/icons/multi/file_type_markdown@3x.png`,
      };
    } else if (
      fileName === "package.json" ||
      fileName === "package-lock.json"
    ) {
      return {
        iconPath: `${iconBaseURL}/preview/icons/multi/file_type_npm@3x.png`,
      };
    } else if (fileName == "vite.config.json") {
      return {
        iconPath: `${iconBaseURL}/preview/icons/multi/file_type_vite@3x.png`,
      };
    } else if (fileName == ".gitignore" || fileName === ".gitkeep") {
      return {
        iconPath: `${iconBaseURL}/preview/icons/multi/file_type_git@3x.png`,
      };
    } else {
      const iconName =
        iconDefs.fileNames[fileName] || iconDefs.fileExtensions[ext] || "text";
      return iconDefs.iconDefinitions[iconName];
    }
  })();

  if (!icon || !icon.iconPath) {
    console.debug("missing icon", fileName, ext, icon);
    return `${iconBaseURL}/preview/files/text.png`;
  }
  return icon.iconPath.match(/^https/)
    ? icon.iconPath
    : icon.iconPath
        .replace("./icons", `${iconBaseURL}/preview`)
        .replace(".svg", ".png");
};
