/** @jsx h **/

import { useSliding } from "@gratico/uikit";
import { component, h } from "alfama";
import { ShellProps } from "../../@types";
import { CheckoutTree } from "./CheckoutTree";

export const NavIsland = component<ShellProps>("NavIsland", (props, utils) => {
  // return null;
  const { sidebarRef, $expanded, mouseEvent } = useSliding(
    utils,
    undefined,
    -1
  );
  $expanded.set(true);
  const { wire, signal, onMount, onUnmount } = utils;
  return (
    <div
      ref={sidebarRef}
      style={
        "top: 60px; bottom: 60px; width: 290px; left: 0; z-index: 21474836;"
      }
      class={
        "flex flex-col layered-bg absolute  left-0  border rounded  overflow-y-auto"
      }
    >
      <div class="pb-2">
        <CheckoutTree {...props} refName={"master"} />
      </div>
    </div>
  );
});
