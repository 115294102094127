/** @jsx h **/
import { component, h, VElement, When } from "alfama";

export type RichComposerType = typeof import("../RichComposer");

export const LLMMessageComposer = component<{
  initialValue: string;
  children?: VElement;
  onSubmit: (text: string) => void;
  onInput: (text: string) => void;
  onKeyDown?: (e: KeyboardEvent) => void;
  reverse?: boolean;
}>(
  "MessageComposer",
  (props, { signal, store, onMount, getContext, onUnmount, wire }) => {
    const $mod = signal<RichComposerType | undefined>("mod", undefined);
    onMount(async () => {
      const mod = await import("../RichComposer");
      $mod.set(mod);
    });
    return (
      <When
        condition={($) => !!$mod.get($)}
        views={{
          true: () => {
            const { RichComposer, setupRichEditor } =
              $mod.get() as RichComposerType;
            return (
              <div
                style="background: var(--component-bg-color);max-height: 200px; overflow-y: auto;"
                class="relative flex  flex-col px-2 flex-1"
                ref={(el) => {
                  setupRichEditor(el, {
                    onSubmit: (text: string) => {
                      props.onSubmit(text);
                    },
                    onInput: (text: string) => {
                      props.onInput(text);
                    },
                    reverse: props.reverse,
                  });
                }}
              ></div>
            );
          },
          false: () => null,
        }}
      ></When>
    );
  }
);
