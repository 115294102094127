export enum ASTKind {
  ISourceFile = "SourceFile",
  ISourceFileImport = "SourceFileImport",
  ISourceFileImportElement = "SourceFileImportElement",
  IReference = "Reference",
  IIdentifier = "Identifier",
  IObjectPaterrn = "ObjectPaterrn",
  IArrayPaterrn = "ArrayPaterrn",

  IValueDefinition = "ValueDefinition",
  ITypeDefinition = "TypeDefinition",

  ILiteralObject = "LiteralObject",
  ILiteralArray = "LiteralArray",

  IValueEvaluation = "ValueEvaluation",
  ITypeEvaluation = "TypeEvaluation",

  IFunction = "Function",
  ITypeFunction = "TypeFunction",

  IStringLiteral = "StringLiteral",
  IPrimitiveLiteral = "PrimitiveLiteral",
  ITypePrimitiveLiteral = "TypePrimitiveLiteral",

  ITypeLiteralObject = "TypeLiteralObject",
  ITypeLiteralArray = "TypeLiteralArray",

  INative = "Native",
  IMacro = "Macro",
  IVoid = "Void",

  IBlock = "Block",
  IAnnotation = "Annotation",
  IGroup = "Group",
}
