/** @jsx h **/
import { CommandItem, MultiStepItem, MultiStepItemType } from "@gratico/sdk";
import { match } from "ts-pattern";

export const pullGitCommand: CommandItem = {
  type: MultiStepItemType.COMMAND,
  id: "pullGitCommand",
  label: "Pull",
  icon: "ri-formula",
  action: {
    name: "Create Function",
    inputs: (selections) => {
      return {
        name: "file",
        type: MultiStepItemType.FOLDER,
      };
    },
    async perform(props, attrs) {},
  },
};

export const gitCommands = [pullGitCommand];
