/** @jsx h **/

import { IPackageDependencies, IPackageManifest } from "@gratico/sdk";
import { component, Fragment, h, Signal, When } from "alfama";

export type PackageInstallProps = {
  $dependencies: Signal<IPackageDependencies & { dirty?: boolean }>;
};

export const Layout = component<{ dependencies: IPackageDependencies }>(
  "Layout",
  (props, { signal }) => {
    const $dependencies = signal("dependencies", props.dependencies);
    return (
      <div style="overflow: hidden;" class="flex flex-col flex-1 p-0 ">
        <Header {...props} $dependencies={$dependencies} />
        <div class="flex  flex-1 border-b ">
          <div class="flex flex-1 flex-col w-1/3 border-r">
            <Sidebar {...props} $dependencies={$dependencies} />
          </div>
          <div
            style="background: rgba(0,0,0,0.15)"
            class="w-2/3 pt-4 rounded p-2"
          >
            <Body {...props} $dependencies={$dependencies} />
          </div>
        </div>
      </div>
    );
  }
);

export const Body = component<PackageInstallProps>(
  "FilesList",
  (props, { signal, wire }) => {
    const $loading = signal("loading", false);
    const $query = signal("query", "");
    const $manifest = signal<IPackageManifest | undefined>(
      "manifest",
      undefined
    );
    return (
      <div class="flex flex-col flex-1 px-4 py-2">
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            const req = await fetch(
              `https://cdn.jsdelivr.net/npm/${$query.get()}/package.json`
            );
            const json = await req.json();
            $manifest.set(json);
          }}
        >
          <div class="form-control">
            <input
              class="input input-bordered input-lg full-w "
              autoFocus={true}
              name="message"
              type="text"
              pattern=".{1,}"
              placeholder="Search NPM for package to install"
              required={true}
              value={wire(($) => $query.get($))}
              onInput={(e) => $query.set(e.currentTarget.value)}
            />
          </div>
        </form>
        <div class="px-2 px-2">
          <When
            condition={($) => {
              const value = $manifest.get($);
              //console.log("v", value);
              return value;
            }}
            views={(pkg: IPackageManifest | undefined) => {
              //console.log("manifest", manifest);
              if (pkg) {
                return (
                  <div class="py-6">
                    <div>
                      <a
                        target="_blank"
                        href={`https://www.npmjs.com/package/${pkg.name}`}
                      >
                        <span class="text-2xl">{pkg.name}</span>
                        <span class="py-1 text-sm"> {pkg.version}</span>
                        <i class="ri-external-link-line px-2"></i>
                      </a>
                    </div>
                    <p class="py-2">{pkg.description}</p>
                    <div class="py-2">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          const existingState = props.$dependencies.get();
                          props.$dependencies.set({
                            ...existingState,
                            dirty: true,
                            manifest: {
                              ...existingState.manifest,
                              dependencies: {
                                ...(existingState.manifest.dependencies || {}),
                                [pkg.name]: `^${pkg.version}`,
                              },
                            },
                          });
                        }}
                        class="btn btn-primary btn-sm text-1xl"
                      >
                        <span class="mr-1">Install</span>
                        <i class="ri-add-circle-line text-2xl"></i>
                      </button>
                    </div>
                  </div>
                );
              } else {
                return "";
              }
            }}
          ></When>
        </div>
      </div>
    );
  }
);

export const Sidebar = component<PackageInstallProps>("FilesList", (props) => {
  return (
    <div class="flex flex-col flex-1">
      <div class="px-4 py-2 flex justify-between items-center ">
        <span class="font-bold">Dependencies </span>
      </div>
      <div class="px-4 py-1 rounded">
        <When
          condition={($) => {
            const state = props.$dependencies.get($);
            const dependencies = {
              ...(state.manifest.dependencies || {}),
              ...(state.manifest.devDependencies || {}),
              ...(state.manifest.peerDependencies || {}),
              ...(state.manifest.optionalDependencies || {}),
            };
            return dependencies;
          }}
          views={function (dependencies) {
            console.log("render dependencies", dependencies);
            return (
              <Fragment>
                {Object.keys(dependencies).map((packageName) => {
                  const version = dependencies[packageName];
                  return (
                    <div class="px-0 py-1">
                      <a
                        target="_blank"
                        href={`https://www.npmjs.com/package/${packageName}`}
                      >
                        {packageName} <i class="ri-external-link-line"></i>
                        <div class="text-sm">{version}</div>
                      </a>
                    </div>
                  );
                })}
              </Fragment>
            );
          }}
        />
      </div>
    </div>
  );
});

export const Header = component<PackageInstallProps>(
  "ChangesHeader",
  (props, { wire }) => {
    return (
      <div class="flex justify-between items-center px-2 py-2 border-b">
        <div class="flex items-center space-x-4">
          <div class="flex items-center text-2xl">
            <span class="mr-2">
              <i class="ri-npmjs-line"></i>
            </span>
            <div class=" px-2 py-1 rounded flex items-center">NPM packages</div>
          </div>
        </div>
        <div class="flex flex-col items-right">
          <button
            disabled={wire(($): any => {
              const state = props.$dependencies.get($);
              console.log("s", state);
              if (!state.dirty) return true as boolean;
              return undefined;
            })}
            class="btn btn-primary btn-sm text-1xl"
          >
            <span class="mr-1">Install</span>
            <i class="ri-install-line text-2xl"></i>
          </button>
        </div>
      </div>
    );
  }
);
