import {
  Awareness,
  encodeAwarenessUpdate,
  applyAwarenessUpdate,
} from "y-protocols/awareness";
import {
  createStore,
  reify,
  getProxyMeta,
  StoreChangeData,
  StoreManager,
  applyStoreChange,
  StoreCursor,
  StoreChange,
} from "alfama/state";
import { IRealtimeProvider, IRealtimeMessage, MessageType } from "../types";
import Emittery from "emittery";
import { Buffer } from "@gratico/fs";

class FakeYDoc {
  clientId: string;
  constructor(clientId: string) {
    this.clientId = clientId;
  }
  on() {}
  off() {}
}

export type IAlfamaMessage = IRealtimeMessage<StoreChange | Uint8Array>;

export type IAlfamaProviderOptions = {
  sendMessage(msg: IAlfamaMessage): Promise<void>;
  disableAwareness?: boolean;
};

export type IAlfamaProvider<T = any> = IRealtimeProvider<
  StoreCursor<T>,
  IAlfamaMessage
>;

export function queryAwareness(provider: IAlfamaProvider) {
  return encodeAwarenessUpdate(
    provider.awareness,
    Array.from(provider.awareness.getStates().keys())
  );
}

export function applyAwareness(provider: IAlfamaProvider, data: Uint8Array) {
  applyAwarenessUpdate(provider.awareness, data, provider);
}

function queryState(provider: IAlfamaProvider): StoreChange {
  return {
    path: [],
    value: JSON.parse(JSON.stringify(reify(provider.doc))) as StoreChangeData,
    data: undefined as any,
  };
}

const createMessage = (
  provider: IAlfamaProvider,
  type: MessageType,
  data?: Uint8Array | StoreChange
): IAlfamaMessage => {
  return {
    id: crypto.randomUUID(),
    type,
    data,
    from: provider.id,
    createdAt: Date.now(),
  };
};

export function createAlfamaProvider(
  docId: string,
  doc: StoreCursor,
  options: IAlfamaProviderOptions
): IAlfamaProvider {
  const id = crypto.randomUUID();
  // typecast because awareness has a harddependency on yjs for some reason
  const ydoc = new FakeYDoc(docId);
  const awareness = new Awareness(ydoc as any);
  const provider: IRealtimeProvider = {
    id,
    docId,
    type: "alfama",
    awareness,
    doc: doc,
    sendMessage: options.sendMessage,
    receiveMessage: (msg: IAlfamaMessage) => {
      if (msg.from === id) return;
      const messageType = msg.type;

      if (messageType == MessageType.QUERY_STATE) {
        const update = queryState(provider);
        const msg = createMessage(provider, MessageType.APPLY_UPDATE, update);
        return options.sendMessage(msg);
      } else if (messageType == MessageType.QUERY_AWARENESS) {
        const awarenessUpdate = queryAwareness(provider as IAlfamaProvider);
        const msg = createMessage(
          provider,
          MessageType.UPDATE_AWARENESS,
          awarenessUpdate
        );
        return options.sendMessage(msg);
      } else if (messageType === MessageType.UPDATE_AWARENESS) {
        applyAwareness(provider, msg.data as Uint8Array);
      } else if (messageType === MessageType.APPLY_UPDATE) {
        provider.applyUpdate(provider, msg.data as StoreChange, true);
      }
    },
    queryState: function () {
      const msg = createMessage(provider, MessageType.QUERY_STATE);
      return provider.sendMessage(msg);
    },
    destroy: function () {
      storeManager.tasks.delete(task);
      awareness.destroy();
    },
    applyUpdate: (
      provider: IAlfamaProvider,
      data: StoreChange,
      skipSync?: boolean
    ) => {
      applyingUpdate = true;
      applyStoreChange(provider.doc, data);
      provider.emitter.emit("updated", data);
      applyingUpdate = false;
    },
    getState: () => {
      return Array.from(
        Buffer.from(JSON.stringify(reify(provider.doc), null, 2))
      );
    },
    getText() {
      return JSON.stringify(reify(provider.doc));
    },
    emitter: new Emittery(),
  };

  const storeManager = getProxyMeta<StoreManager>(doc as StoreCursor<any>);

  // to prevent recusive updates
  var applyingUpdate = false;

  const updateDispatcher = (update: StoreChange) => {
    const msg = createMessage(provider, MessageType.APPLY_UPDATE, update);
    provider.sendMessage(msg);
  };

  const task = {
    path: [],
    observor: (change: StoreChange) => {
      if (!applyingUpdate) {
        updateDispatcher(change);
      }
    },
  };
  storeManager.tasks.add(task);

  if (!options.disableAwareness) {
    const awarenessDispatcher = (
      { added, updated, removed }: any,
      _origin: any
    ) => {
      const changedClients = added.concat(updated).concat(removed);
      const msg = createMessage(
        provider,
        MessageType.UPDATE_AWARENESS,
        encodeAwarenessUpdate(awareness, changedClients)
      );
      provider.sendMessage(msg);
    };
    awareness.on("update", awarenessDispatcher);
  }

  return provider;
}
