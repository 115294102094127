/** @jsx h **/

import { component, h } from "alfama";

export const LiveMessage = component("LiveMessage", (props) => {
  return (
    <div class="flex px-2 py-2">
      <div class="flex flex-col items-start justify-start w-[50px]">
        <div class=" avatar">
          <div class="w-10 rounded-full text-2xl">
            <i class="ri-shining-line"></i>
          </div>
        </div>
        <div class="hidden flex justify-center items-center flex-col">AI</div>
      </div>
      <div class="flex-1">
        <div
          id="gratico-chat-liveEL"
          data-live="true"
          key="last"
          class="prose"
        ></div>
      </div>
    </div>
  );
});
