import {
  IMasterKernelAPI,
  IViewportKernel,
  KernelConnection,
} from "@gratico/kernel";
import { component, defineContext, Signal, VElement } from "alfama";
export type { KernelConnection } from "@gratico/kernel";

export const KernelContext =
  defineContext<Signal<KernelConnection>>("KernelContext");

export const KernelProvider = component<{
  children: VElement;
  kernel: IViewportKernel;
  master: IMasterKernelAPI;
  viewport: any;
}>("KernelConnection", (props, { setContext, signal }) => {
  const $kernelConnection = signal<KernelConnection>("kernelConnection", {
    kernel: props.kernel,
    master: props.master,
    viewport: props.viewport,
  });
  setContext(KernelContext, $kernelConnection);
  return props.children;
});
