/** @jsx h **/
import {
  IBranchChanges,
  IMasterKernelAPI,
  IPackageDependencies,
  IPackageLock,
  IPackageManifest,
  IViewportKernel,
} from "@gratico/sdk";
import { Fragment, When, component, h } from "alfama";
import { getCurrentProjectParameters } from "../../constants";
import { Layout, PackageInstallProps } from "./PackageInstaller";
import { SVGLoader } from "../Layout/Loaders/index";

export const PackageInstaller = component<{
  master: IMasterKernelAPI;
  kernel: IViewportKernel;
  onClose: Function;
}>("PackageInstaller", (props, { getContext, signal, onMount }) => {
  const { master, kernel } = props;
  const $packageDependencies = signal<IPackageDependencies | undefined>(
    "packageDependencies",
    undefined
  );

  onMount(async () => {
    const parameters = getCurrentProjectParameters(kernel, location);
    const dependencies = await master.getProjectDependencies(
      parameters.projectId,
      parameters.ref
    );
    console.log("$packageDependencies", dependencies);
    $packageDependencies.set(dependencies);
  });

  return (
    <Fragment>
      <div
        style="border: 1px solid var(--component-border-color);  box-shadow: var(--component-box-shadow);"
        class="modal-box w-[100%] max-w-[1000px] layered-bg p-0"
      >
        <div class="flex flex-col h-[100vh] max-h-[700px]">
          <When
            condition={($) => !!$packageDependencies.get($)}
            views={{
              true: () => (
                <Layout
                  dependencies={
                    $packageDependencies.get() as IPackageDependencies
                  }
                />
              ),
              false: () => (
                <div class="full-h full-w flex items-center justify-center flex-1">
                  <SVGLoader />
                </div>
              ),
            }}
          ></When>
        </div>
      </div>
      <form method="dialog" class="modal-backdrop">
        <button>close</button>
      </form>
    </Fragment>
  );
});
