/** @jsx h **/

import { component, Fragment, h, VElement, When } from "alfama";

import { Dropdown } from "@gratico/uikit";
import animate from "animejs";
import { ShellProps } from "../../@types";
import { KernelContext, ModalsAPIContext } from "../../providers";
import { Changes } from "../Changes";
import { Chat } from "../Chat";
import { Diagnostics } from "../Diagnostics";
import { Hydra } from "../Hydra";
import { Market } from "../Market";
import { PackageInstaller } from "../PackageInstaller";
import { GitInfoMenu } from "./GitInfoMenu";
import { keyMap } from "../../utils/hydra/keyMap";

export const MenuItem = component<{
  onClick: (e: MouseEvent) => void;
  children: VElement;
  title: string;
}>("MenuItem", (props, utils) => {
  return (
    <div class="px-2 py-2 flex justify-center items-center">
      <a
        href="#"
        rel={props.title}
        class="tooltip flex justify-center items-center"
        data-tip={props.title}
        onClick={props.onClick}
      >
        {props.children}
      </a>
    </div>
  );
});

export const OverviewBarInner = component<ShellProps>(
  "ChatsIsland",
  (props, { signal, onMount, onUnmount, wire, getContext }) => {
    const modalsAPI = getContext(ModalsAPIContext);
    const { master, kernel } = getContext(KernelContext).get();
    return (
      <Fragment>
        <div class="flex flex-1 justify-start items-center ">
          <div class="flex flex-1 justify-start items-center px-1">
            <MenuItem
              title="Review changes"
              onClick={(e) => {
                e.preventDefault();
                modalsAPI.showModel(
                  <Changes
                    {...{ master, kernel }}
                    onClose={() => modalsAPI.closeModal()}
                  />
                );
              }}
            >
              <i class="devicon-git-plain"></i>
            </MenuItem>
            {false && (
              <MenuItem
                title="Install NPM packages"
                onClick={(e) => {
                  e.preventDefault();
                  modalsAPI.showModel(
                    <PackageInstaller
                      {...{ master, kernel }}
                      onClose={() => modalsAPI.closeModal()}
                    />
                  );
                }}
              >
                <i class="ri-npmjs-line"></i>
              </MenuItem>
            )}
            <div class="px-2 py-2 flex justify-center items-center">
              <a
                href="#"
                rel={"Diagnostics"}
                class="tooltip flex justify-center items-center"
                data-tip={"Diagnostics"}
                onClick={(e) => {
                  e.preventDefault();
                  props.$activePanel.set("diagnostics");
                }}
              >
                <div class="indicator">
                  <span
                    style={wire(($): string => {
                      const diagnostics = $(props.$diagnostics);
                      return diagnostics.diagnostics.length == 0
                        ? "display: none;"
                        : "background: indianred;";
                    })}
                    class="indicator-item badge badge-secondary text-xs"
                  >
                    {wire(($) => {
                      const diagnostics = $(props.$diagnostics);
                      return diagnostics.diagnostics.length;
                    })}
                  </span>
                  <i
                    class={wire(($): string => {
                      const state = $(props.$diagnostics);
                      return state.loading
                        ? "ri-error-warning-fill"
                        : "ri-error-warning-line";
                    })}
                  ></i>
                </div>
              </a>
            </div>
            {false && (
              <MenuItem
                title="Market"
                onClick={(e) => {
                  e.preventDefault();
                  modalsAPI.showModel(
                    <Market onClose={() => modalsAPI.closeModal()} />
                  );
                }}
              >
                <i class="ri-shapes-line"></i>
              </MenuItem>
            )}
          </div>
          <div class="px-1 py-2 flex justify-center items-center hidden">
            <div class="flex text-sm px-1">
              <div
                style="background: #111; border: 1px solid var(--component-border-color);"
                class="bg-white text-white rounded px-1"
              >
                <i class="las la-magic"></i> context
              </div>
            </div>
          </div>
          {!props.kernel.params.session.project.providerId && (
            <Dropdown
              position={["top", "end"]}
              icon="ri-terminal-box-fill"
              renderBody={() => <GitInfoMenu {...props} />}
            />
          )}
        </div>
      </Fragment>
    );
  }
);

export const OverviewBar = component<ShellProps>(
  "ChatsIsland",
  (props, { signal, onMount, onUnmount, wire, getContext }) => {
    let menuContainer: HTMLElement | undefined;
    const defaultHeight = 45;
    const expandedHeight = 650;
    const $activePanel = props.$activePanel;
    const $height = signal("height", defaultHeight);
    onMount(() => {
      wire(($, { previousValue }) => {
        if (!menuContainer) return;
        const active = $activePanel.get($);
        if (active === previousValue) return active;
        const finalHeight = active === "hydra" ? 300 : expandedHeight;
        animate({
          targets: menuContainer,
          height: active ? finalHeight : defaultHeight,
        });

        return active;
      }).run();
    });
    const handler = async (e: MouseEvent) => {
      const path = e.composedPath();
      if (path.indexOf(menuContainer as EventTarget) == -1) {
        $activePanel.set(undefined);
      }
    };
    onMount(() => {
      document.addEventListener("click", handler);
    });
    onUnmount(() => {
      document.removeEventListener("click", handler);
    });
    return (
      <div
        style={`overflow: visible; position: fixed; bottom: 0; left: 60px; right: 60px;z-index: 214748364;`}
      >
        <div
          onClick={async () => {
            //
          }}
          ref={(el) => (menuContainer = el)}
          style={wire(
            ($) => `height: ${$height.get($)}px; z-index: 214748364; `
          )}
          class=" flex layered-bg absolute bottom-0 left-8 right-8 border rounded-r rounded-l overflow-visible"
        >
          <When
            condition={$activePanel.get}
            views={{
              undefined: () => <OverviewBarInner {...props} />,
              diagnostics: () => <Diagnostics {...props} />,
              chat: () => <Chat {...props} />,
              hydra: () => <Hydra {...props} keyMap={keyMap} />,
            }}
          />
        </div>
      </div>
    );
  }
);
