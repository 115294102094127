/** @jsx h **/
import { getBufferIdFromRouterParams, IBufferType } from "@gratico/kernel";
import { getBufferIcon, IRefBufferRouteParams } from "@gratico/sdk";
import { component, produce, reify } from "alfama";
import { ParentRouteContext } from "alfama-router";
import { BuffersContext, KernelContext } from "../../providers/index";

export const RefRoute = component(
  "RefRoute",
  (props, { getContext, onMount, wire }) => {
    const route = getContext(ParentRouteContext).get();
    const $buffers = getContext(BuffersContext);
    onMount(async () => {
      console.log("route", route);
      if (!route || !route.params.projectId || !route.params.ref) {
        console.error("no params", route?.params, route);
        return;
      }
      // todo: look into how to make path parsing typesafe the way hono does
      // important: this isn't typesafe
      const bufferParams: IRefBufferRouteParams = {
        projectId: route.params.projectId,
        ref: route.params.ref,
        type: IBufferType.REF,
      };
      const bufferId = getBufferIdFromRouterParams(bufferParams);

      const existing = reify($buffers.list).find((el) => el.id === bufferId);
      // console.log("existing", existing);
      if (existing) {
        produce($buffers, (s) => {
          s.activeBufferId = bufferId;
        });
      } else {
        try {
          const tempBuffer = {
            ...bufferParams,
            ref: bufferParams.ref,
            id: bufferId,
            createdAt: Date.now(),
          };
          console.log("tempBuffer", tempBuffer);
          produce($buffers, (s) => {
            s.list.push(tempBuffer);
            s.activeBufferId = tempBuffer.id;
          });
        } catch (e) {
          // if fails due to git conflict in lock file
          console.error(e);
        }
      }
    });
    return null;
  }
);
