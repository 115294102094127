import {
  defineContext,
  component,
  VElement,
  StoreCursor,
  createStore,
} from "alfama";
import { IBuffersState } from "@gratico/sdk";

export const BuffersContext =
  defineContext<StoreCursor<IBuffersState>>("BuffersContext");

export const BuffersProvider = component<{
  children: VElement;
}>("Buffers", (props, { setContext, signal, store }) => {
  const $buffers = store<IBuffersState>("store", { list: [] });
  setContext(BuffersContext, $buffers);
  return props.children;
});
