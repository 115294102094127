import { IBot } from "@gratico/sdk";

export const graticoBot: IBot = {
  id: "gratico",
  trigger: "gratico",
  config: {
    schema: {},
  },
  async attachments(context, config, prompt) {
    return [];
  },
  async instructions(context, config, prompt, attachments) {
    return [
      "Please be concise",
      `Output the code along with its filename and any additional instructions in following format
path: {path}
\`\`\`
{code}
\`\`\`
`,
    ];
  },
  output: {
    async processor(str) {
      return {
        artifacts: [],
      };
    },
  },
};
