import { autocomplete } from "@gratico/uikit";
// import { IEditorAction } from "../../../../../kernel/sdk/src/types";
import {
  ShellProps,
  MultiStepItem,
  MultiStepInput,
  MultiStepItemType,
  CommandItem,
} from "@gratico/sdk";

export type MultiStepOptions = {
  container: HTMLDivElement;
  render: (item: MultiStepItem, value: any, index: number) => HTMLDivElement;
  fetchOptions: (selections: MultiStepInput[], query: string) => Promise<any[]>;
  onChange: (value: MultiStepInput[]) => void;
  value: MultiStepInput[];
};
export const setupMultiStepAutocomplete = ({
  container,
  render,
  fetchOptions,
  value,
  onChange,
}: MultiStepOptions) => {
  const input = container.querySelector("input") as HTMLInputElement;
  const autocompleteContainer = container.querySelector(
    `[data-autocomplete-container]`
  ) as HTMLDivElement;

  const select = autocomplete<MultiStepItem>({
    input: input,
    minLength: 0,
    showOnFocus: true,
    container: autocompleteContainer,
    customize(input, inputRect, container, maxHeight) {
      container.style.width = "100%";
      container.style.top = 50 + "px";
      container.style.left = 0 + "px";
    },
    fetch: async (query, update) => {
      const suggestions = await fetchOptions(value, query);
      update(suggestions);
    },
    render,
    onSelect: (item) => {
      const rootCommand = value[0] as MultiStepInput<CommandItem>;
      const inputValue = rootCommand.value?.action.inputs(value);
      if (inputValue) {
        console.log("item", { item, inputValue });
        const newInput: MultiStepInput = { ...inputValue, value: item };
        value.push(newInput);
      }
      input.value = "";
      input.blur();
      input.focus();
      onChange(value);
    },
  });
  return select;
};
