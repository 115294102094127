import { IBranchChange } from "@gratico/sdk";

export async function stageFiles(
  projectId: string,
  ref: string,
  changes: IBranchChange[]
) {
  const req = await fetch(
    `/~/api/v1/git/stagings?ref=${ref}&repoId=${projectId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        branch: ref,
      }),
    }
  );
  if (!req.ok) {
    throw new Error(await req.text());
  }
}

export async function saveFiles(
  projectId: string,
  ref: string,
  changes: IBranchChange[]
) {
  await Promise.all(
    changes
      .filter((el) => el.isDirty)
      .map((change) => saveFile(projectId, ref, change))
  );
}

export async function saveFile(
  projectId: string,
  ref: string,
  change: IBranchChange
) {
  const req = await fetch(
    `/~/api/v1/buffers/save/${encodeURIComponent(
      change.path
    )}?ref=${ref}&projectId=${projectId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        branch: ref,
      }),
    }
  );
  if (!req.ok) {
    throw new Error(await req.text());
  }
}

export async function commit(
  projectId: string,
  ref: string,
  changes: IBranchChange[],
  msg: string,
  description: string
) {
  const req = await fetch(
    `/~/api/v1/git/commits?ref=${ref}&repoId=${projectId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        branch: ref,
        message: msg,
      }),
    }
  );
  if (!req.ok) {
    throw new Error(await req.text());
  }
}

export async function commitChanges(
  projectId: string,
  ref: string,
  changes: IBranchChange[],
  msg: string,
  description: string
) {
  await saveFiles(projectId, ref, changes);
  await stageFiles(projectId, ref, changes);
  await commit(projectId, ref, changes, msg, description);
}
