/** @jsx h **/

import {
  IMasterKernelAPI,
  IViewportKernel,
  IDiagnostics,
  getBufferRouteURL,
  IBufferType,
} from "@gratico/sdk";
import {
  component,
  Fragment,
  h,
  Signal,
  VElement,
  StoreCursor,
  Each,
  reify,
} from "alfama";
import pathe from "pathe";
import { Link } from "alfama-router";
import { ShellProps } from "../../@types";

export const Diagnostics = component<ShellProps>(
  "Diagnostics",
  (props, utils) => {
    return (
      <div class="h-full w-full">
        <div class="flex flex-col overflow-hidden h-full">
          <div
            style="height: 50px;"
            class="grid grid-cols-[90px_1fr_400px]  font-bold text-left p-2 border-b"
          >
            <div></div>
            <div>Message</div>
            <div>Path</div>
          </div>
          <div
            style="background: var(--component-bg-color);"
            class="h-full overflow-y-auto"
          >
            <Each
              cursor={props.$diagnostics.diagnostics}
              renderItem={(cursor) => {
                const diagnostic = reify(cursor());
                const basename = pathe.basename(diagnostic.path);
                const dirname = pathe.dirname(diagnostic.path);
                const { project } = props.kernel.params.session;
                const projectId = project.id;
                const bufferURL = getBufferRouteURL({
                  projectId,
                  ref: props.$activeRef.get() || project.defaultBranch,
                  appName: "codemirror",
                  path: diagnostic.path,
                  type: IBufferType.FILE,
                });
                return (
                  <div class="grid grid-cols-[90px_1fr_400px] gap-2 p-2 border-b ">
                    <div class="flex items-center">
                      <i class="ri-error-warning-line"></i>
                    </div>
                    <div class="font-mono flex items-center">
                      {diagnostic.message}
                    </div>
                    <div class="font-mono flex items-center">
                      <Link href={bufferURL}>
                        <span>{basename}</span>
                        <span class="text-xs block break-all">{dirname}</span>
                      </Link>
                    </div>
                  </div>
                );
              }}
            />
          </div>
        </div>
      </div>
    );
  }
);
