/** @jsx h **/
import {
  CommandItem,
  MultiStepInput,
  IMasterKernelAPI,
  IViewportKernel,
  MultiStepItem,
  MultiStepItemType,
} from "@gratico/sdk";
import { setupMultiStepAutocomplete } from "@gratico/uikit";
import { component, Fragment, h, Signal } from "alfama";
import { RouterContext } from "alfama-router";
import { match, P } from "ts-pattern";

const fetchOptions = async (
  value: MultiStepInput[],
  props: {
    master: IMasterKernelAPI;
    kernel: IViewportKernel;
    command: CommandItem;
  },
  _selections: MultiStepInput[],
  query: string
): Promise<MultiStepItem[]> => {
  const selections = value;
  console.log("fetch", { selections, query });
  const { project } = props.kernel.params.session;
  const results = await props.master.searchSymbols(
    project.id,
    project.defaultBranch,
    undefined,
    query
  );
  return results.map((el) => ({
    type: MultiStepItemType.SYMBOL,
    value: el.path,
    label: el.name,
    meta: el,
  }));
  return [];
};

function render(item: MultiStepItem, currentValue: MultiStepInput[]) {
  const itemElement = document.createElement("div");
  if (item.type === MultiStepItemType.SYMBOL) {
    itemElement.innerHTML = `
<div>
  <div class="flex">${
    ["type", "interface"].includes(item.meta.kind)
      ? `<i class="ri-box-3-line"></i>`
      : `<i class="ri-formula"></i>`
  } <div class="flex-1 truncate pl-1">${item.meta.name}</div></div>
  <div class="text-xs truncate">${item.meta.path}</div>
</div>`;
  } else if (
    item.type === MultiStepItemType.FILE ||
    item.type === MultiStepItemType.FOLDER
  ) {
    itemElement.innerHTML = `
<div>
  <div class="flex">${
    ["type", "interface"].includes("type")
      ? `<i class="ri-box-3-line"></i>`
      : `<i class="ri-formula"></i>`
  } <div class="flex-1 truncate pl-1">${item.meta.name}</div></div>
  <div class="text-xs truncate">${item.meta.path}</div>
</div>`;
  } else if (item.type === MultiStepItemType.COMMAND) {
    itemElement.innerHTML = `
<div>
  <div class="flex">
  ${item.icon ? `<i class="${item.icon}"></i>` : ""}
  <div class="flex-1 truncate pl-1">${item.action.name}</div></div>
  ${
    item.action.description
      ? `<div class="text-xs truncate">${item.action.description}</div>`
      : ""
  }
</div>`;
  }
  return itemElement;
}

export const ActionBar = component<{
  onClose: Function;
  master: IMasterKernelAPI;
  kernel: IViewportKernel;
  command: CommandItem;
}>("ActionBar", (props, { onMount, getContext, signal }) => {
  const router = getContext(RouterContext).get();
  const value: MultiStepInput[] = [
    { name: "command", type: MultiStepItemType.COMMAND, value: props.command },
  ];
  return (
    <Fragment>
      <div
        style="position: absolute; left: 50%; top: 120px; width: 550px; z-index: 2147483647; transform: translate(-50%); "
        class="absolute flex flex-col rounded"
      >
        <div
          class="form-control"
          ref={(container) => {
            return setupMultiStepAutocomplete({
              container,
              render,
              fetchOptions: fetchOptions.bind(null, value, props),
              onChange: async (selections) => {
                console.log("onChange", selections);
              },
              value,
            });
          }}
        >
          <label class="">
            <input
              class="w-full px-4 py-4"
              autofocus={true}
              name="name"
              type="text"
              pattern=".{1,}"
              placeholder={"Search project..."}
              required
              autocomplete={"off"}
              onKeyDown={(e) => {
                if (e.key === "Escape") {
                  props.onClose();
                }
              }}
              autoFocus={true}
            />
            <span
              style="visibility: hidden; top: 50%; right: 0; transform: translateY(-50%) translateX(-100%);"
              class="loading loading-spinner loading-xs absolute"
            ></span>
          </label>
          <div class="glossy-bg" data-autocomplete-container="true"></div>
        </div>
      </div>
      <form method="dialog" class="modal-backdrop">
        <button>close</button>
      </form>
    </Fragment>
  );
});
