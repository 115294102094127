import { IBaseKernel, IMasterKernel } from "@gratico/sdk";
import { IFs, createFS, fromJSON, Volume, Buffer } from "@gratico/fs";
import { queue } from "@gratico/stdlib";

export async function _checkoutRepo(
  kernel: IMasterKernel,
  projectId: string,
  branch: string
) {
  const checkoutId = getCheckoutId(kernel, projectId, branch);

  console.groupCollapsed("checkoutRepo/" + checkoutId);
  console.time("getRepoFiles/" + checkoutId);
  const files = await getRepoFiles(kernel, projectId, branch);
  console.timeEnd("getRepoFiles/" + checkoutId);
  console.log("f", files);
  const json = files.reduce<{ [key: string]: Uint8Array }>((state, el: any) => {
    state["/" + el.id] = new Uint8Array(el.content);
    return state;
  }, {});
  console.log("j", json);
  const fs = createFS();
  console.time("fromJSON/" + checkoutId);
  fromJSON(fs, json);
  console.timeEnd("fromJSON/" + checkoutId);
  console.groupEnd();
  //  try {
  //    fs.mkdirSync("/");
  //  } catch (e) {}
  return { fs: fs as IFs, id: checkoutId };
}
export const checkoutRepo = queue(_checkoutRepo, 1)();

export const getCheckoutId = (
  kernel: IBaseKernel,
  projectId: string,
  branch: string
) => {
  return encodeURIComponent(`${projectId}/${encodeURIComponent(branch)}`);
};

export const getRepoFiles = async (
  kernel: IMasterKernel,
  projectId: string,
  branch: string
) => {
  const { cache } = kernel;
  let store = cache.stores.repo;
  console.log("projectId", projectId);

  const storeId = getCheckoutId(kernel, projectId, branch);
  if (!store) {
    store = await cache.createStore(storeId, async (checkpoint) => {
      //console.log("getRepoFiles checkpoint", checkpoint);
      const previousCheckpoint = checkpoint ? checkpoint : {};
      const req = await fetch(`/~/api/v1/git/packs`, {
        method: "post",
        credentials: "include",
        mode: "cors",
        body: JSON.stringify({
          tree: previousCheckpoint,
          repoId: projectId,
          ref: branch,
        }),
        headers: {
          //            authorization: `bearer ${kernel.params.session.credentials.jwt}`,
          "content-type": "application/json",
        },
      });
      if (req.status == 201) {
        const data = await req.json();
        const {
          contents,
          checkpoint: newCheckpoint,
          changes: { deleted },
        } = data;
        return { data: contents, checkpoint: newCheckpoint, deleted };
      } else {
        throw new Error(await req.text());
      }
    });
  }
  //console.log(store);
  kernel.localChannel.postMessage({
    message: `fetching project files`,
  });
  await store.refresh();
  return store.getAll();
};
