/** @jsx h **/

import { TreeNode, TreeNodeItem } from "@gratico/banyan";
import {
  createAlfamaProvider,
  getCheckoutId,
  IAlfamaMessage,
  IBufferType,
} from "@gratico/kernel";
import { component, getCursor, h, reify, StoreCursor } from "alfama";
import pathe from "pathe";
import { ShellProps } from "../../../@types";
import { getBufferRouteURL } from "@gratico/sdk";

export { IModeTypes } from "@gratico/banyan";

export const CheckoutTree = component<ShellProps & { refName: string }>(
  "CheckoutTree",
  (props, { store, onMount, signal, onUnmount }) => {
    const { kernel, refName, master } = props;
    const $loaded = signal("loaded", false);
    const projectId = props.kernel.params.session.project.id;
    const checkoutId = getCheckoutId(props.kernel, projectId, refName);

    const $tree = store<TreeNode>("tree", {
      name: refName,
      path: "/",
      children: [],
      isDirectory: true,
      meta: { root: true },
      type: "dir",
      icon: "https://cdn.jsdelivr.net/npm/vscode-symbols@0.0.18/src/icons/folders/folder-context.svg",
      state: {},
    });
    //  console.log($tree);
    const treeProvider = createAlfamaProvider(refName, $tree, {
      disableAwareness: true,
      async sendMessage(msg) {
        const topic = `tree:master:${checkoutId}`;
        return kernel.pubsub.publish(topic, msg);
      },
    });
    const handler = (msg: IAlfamaMessage) => {
      //console.log("msg", msg);
      treeProvider.receiveMessage(msg);
    };
    const topics = `checkoutTree:${checkoutId}:change`;

    const requestExpansion = async (c: StoreCursor<TreeNode>) => {
      const item = reify(c);
      const files = await props.master.getNodes(
        props.kernel.params.session.project.id,
        refName,
        item.path,
        getCursor(c)
      );
    };

    onMount(async () => {
      //    await treeProvider.queryState();
      kernel.pubsub.emitter.on(topics, handler);
    });
    onUnmount(() => kernel.pubsub.emitter.off(topics, handler));

    onMount(async () => {
      try {
        const item = reify($tree);
        //        console.log(
        //          "rendering false",
        //          checkoutId,
        //          JSON.parse(JSON.stringify(item))
        //        );

        if (!item.state.loaded) {
          await requestExpansion($tree);
        }
        const { kernel } = props;
        //console.log("requesting", `checkoutTree:${checkoutId}:boot`);
        const reply = await kernel.pubsub.request(
          `checkoutTree:${checkoutId}:boot`,
          {}
        );
        //console.log("reply", reply);
        treeProvider.applyUpdate(treeProvider, reply, true);
      } catch (e) {
        console.error(e);
      }
    });

    return (
      <div class={"w-full h-full relative"}>
        <TreeNodeItem
          {...props}
          root={true}
          treeProvider={treeProvider}
          key="true"
          cursor={() => $tree}
          requestExpansion={requestExpansion}
          handleItemClick={(item, e) => {}}
          itemHref={(node) => {
            const fileItem = reify(node);
            if (fileItem.meta && fileItem.meta.root) {
              return undefined;
            } else {
              const ext = pathe.extname(fileItem.path);
              const url = getBufferRouteURL({
                appName: [".ts", ".tsx"].includes(ext)
                  ? "whiteboard"
                  : "codemirror",
                projectId,
                ref: "master",
                path: fileItem.path,
                type: IBufferType.FILE,
              });
              return url;
            }
          }}
        />
      </div>
    );
  }
);
