/** @jsx h **/

import { component, Fragment, h } from "alfama";
import snarkdown from "snarkdown";
import { IChatMessage, IChatMessageType } from "@gratico/sdk";

export const ChatMessage = component<{ message: IChatMessage }>(
  "ChatMessage",
  (props) => {
    const { message } = props;
    console.log("message", message);
    return (
      <Fragment>
        {[IChatMessageType.ASSISTANT, IChatMessageType.USER].indexOf(
          message.type
        ) > -1 && (
          <div class="flex px-2 py-2">
            <div class="flex flex-col items-start justify-start w-[50px]">
              <div class=" avatar">
                <div class="w-10 rounded-full text-2xl">
                  {message.type === IChatMessageType.USER ? (
                    <i class="ri-user-line"></i>
                  ) : (
                    <i class="ri-shining-line"></i>
                  )}
                </div>
              </div>
              <div class="hidden flex justify-center items-center flex-col">
                {message.type === IChatMessageType.USER ? "User" : "AI"}
              </div>
            </div>
            <div class="flex-1">
              <div
                dangerouslySetInnerHTML={{
                  __html: snarkdown(message.content + ""),
                }}
                class=" prose"
              ></div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
);
