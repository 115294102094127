export * from "./async";

export function debounce<T extends (...args: any) => any>(
  func: T,
  wait: number
) {
  let timeout: NodeJS.Timeout | undefined;

  return function (...args: Parameters<T>) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(null, args), wait);
  };
}

export function throttle<T extends (...args: any) => any>(
  func: T,
  wait: number
) {
  let lastTime = 0;

  return function (...args: Parameters<T>) {
    const now = Date.now();
    if (now - lastTime >= wait) {
      lastTime = now;
      func.apply(null, args);
    }
  };
}
