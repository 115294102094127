/** @jsx h **/
import { searchProjectCommand } from "@gratico/kernel";
import { HydraKeyMap, HydraKeyMapType } from "@gratico/sdk";
import { buffersKeyMap } from "./buffers";
import { codeKeyMap } from "./code";
import { gitKeyMap } from "./git/index";
import { filesKeyMap } from "./files/index";

export const keyMap: HydraKeyMap[] = [
  { id: "leader", type: HydraKeyMapType.GROUP, key: "Space", name: "Hydra" },
  {
    id: "searchProject",
    parentId: "leader",
    type: HydraKeyMapType.ACTION,
    key: "/",
    name: "Search Project",
    action: searchProjectCommand,
  },
  ...buffersKeyMap,
  ...codeKeyMap,
  ...gitKeyMap,
  ...filesKeyMap,
];
