export function parallelLimit(tasks: any[], limit: number) {
  const semaphore = new Array(limit).fill(Promise.resolve());
  return Promise.all(
    tasks.map((task, i) => {
      const slot = i % limit;
      return (semaphore[slot] = semaphore[slot].then(() => task()));
    })
  );
}

export function retry<T = any>(
  retries = 3,
  fn: () => Promise<T>,
  delay = 1000
): Promise<T> {
  return fn().catch((err) => {
    if (retries <= 0) throw err;
    return new Promise((resolve) => setTimeout(resolve, delay)).then(() =>
      retry(retries - 1, fn, delay)
    );
  });
}

export const queue = <T extends Function>(
  fn: T,
  concurrency = 1
): (() => T) => {
  let currentPromise: undefined | Promise<unknown>;
  // todo: fix typecast here
  return function () {
    return async function (this: ThisParameterType<typeof fn>, ...args: any) {
      try {
        currentPromise && (await currentPromise);
      } catch (e) {
        //throw e;
      }
      //console.log("creating checkout queue", currentPromise);
      currentPromise = fn.apply(this, args);
      return currentPromise;
    };
  } as any;
};
