import { IBaseKernel, IRuntime, IRuntimeFetchers } from "@gratico/sdk";

export const ES_MODULE_SHIM_URL =
  "https://cdn.jsdelivr.net/npm/es-module-shims@1.7.3/dist/es-module-shims.js";

export function getESShimOptions(
  kernel: IBaseKernel,
  fetchers: IRuntimeFetchers,
  builtins: Record<string, any> = {}
) {
  return {
    // Enable Shim Mode
    shimMode: true, // default false
    // Enable newer modules features
    polyfillEnable: ["css-modules", "json-modules"], // default empty
    // Custom CSP nonce
    nonce: "n0nce", // default is automatic detection
    // Don't retrigger load events on module scripts
    noLoadEventRetriggers: true, // default false
    // Clean up blob URLs after execution
    revokeBlobURLs: false, // default false
    // Secure mode to not support loading modules without integrity
    // (integrity is always verified even when disabled though)
    enforceIntegrity: false, // default false
    // Permit overrides to import maps
    mapOverrides: true, // default false

    // -- Hooks --
    // Module load error
    onerror: (e: Error) => {
      console.error(e);
    }, // default noop
    // Called when polyfill mode first engages
    onpolyfill: () => {}, // default logs to the console
    // Hook all module resolutions;
    resolve: async (
      importName: string,
      parentUrl: string,
      defaultResolve: Function
    ) => {
      if (importName[0] !== ".") return defaultResolve(importName, parentUrl);
      const url = new URL(parentUrl);
      const resolver = fetchers[url.origin]
        ? fetchers[url.origin].resolver
        : defaultResolve;
      const p = await resolver(importName, parentUrl, defaultResolve);
      //console.log("resolve", id, parentUrl, p);
      return p;
    }, // default is spec resolution
    // Hook source fetch function
    fetch: async (urlString: string, options: any) => {
      const url = new URL(urlString);
      const fetcher = fetchers[url.origin]?.fetcher;
      if (fetcher) {
        const { text: file } = await fetcher(urlString, options);
        return new Response(
          new Blob([file], { type: "application/javascript" })
        );
      } else {
        return fetch(urlString, options);
      }
    }, // default is native
    // Hook import.meta construction
    meta: (meta: any, url: string) => meta, // default is noop
    // Hook top-level imports
    onimport: (url: string, options: any, parentUrl: string) => {
      //console.log("onimport", url, options);
    }, // default is noop
  };
}

export async function createRuntime(
  kernel: IBaseKernel,
  fetchers: IRuntimeFetchers,
  builtins: Record<string, any> = {},
  glb: any = globalThis
): Promise<IRuntime> {
  glb.esmsInitOptions = getESShimOptions(kernel, fetchers, builtins);
  /* @vite-ignore */
  await import(ES_MODULE_SHIM_URL);
  //  console.log("imported", ES_MODULE_SHIM_URL);
  return {
    import: glb["importShim"],
    importSpecifier: async (specifier: string) => {
      if (builtins[specifier]) {
        /* @vite-ignore */
        return import(builtins[specifier]);
      } else {
        return glb["importShim"](specifier);
      }
    },
    pkgs: [],
    fetchers,
    builtins,
  };
}
