/** @jsx h **/
import { debounce } from "@gratico/stdlib";
import { ComponentUtils, Signal } from "alfama";
import animate, { AnimeInstance } from "animejs";
import { IViewportKernel } from "@gratico/sdk";
import { resolve } from "dns";

export const useDockState = (
  utils: ComponentUtils,
  kernel: IViewportKernel,
  configKey: string
) => {
  const dockedValue = kernel.userConfig.get(configKey) || false;
  const $docked = utils.signal("docked", dockedValue);

  utils
    .wire(($) => {
      const val = $docked.get($);
      kernel.userConfig.set(configKey, val);
      kernel.userConfig.set(configKey, val);
    })
    .run();

  return { $docked };
};

export function getParents(a: ParentNode | null) {
  var els = [];
  while (a) {
    els.unshift(a);
    a = a.parentNode;
  }
  return els;
}

export const useSliding = (
  utils: ComponentUtils,
  $docked?: Signal<boolean>,
  direction: -1 | 1 = 1
) => {
  const $expanded = utils.signal<boolean | undefined>("expanded", undefined);
  const $translateX = utils.signal("translateX", 90);

  let sidebarRef: HTMLElement | null = null;

  const handleMouseMove = debounce((e: MouseEvent) => {
    const path = getParents(e.target as HTMLElement);
    //console.log("handleMouseMove", path, sidebarRef);
    if (sidebarRef && path.includes(sidebarRef)) {
      $expanded.set(true);
      handleSlideAnimation();
    } else {
      $expanded.set(false);
      handleSlideAnimation();
      //console.log("set false", $expanded.get());
    }
  }, 100);
  let animation: AnimeInstance | undefined;
  const handleSlideAnimation = async () => {
    if (sidebarRef) {
      const expanded = $expanded.get();
      const docked = $docked?.get();
      const translateX = (docked ? 0 : expanded ? 0 : 90) * direction;
      //    console.log(
      //      "handleSlideAnimation",
      //      expanded,
      //      docked,
      //      translateX,
      //      sidebarRef
      //    );
      //console.log("animation", animation?.remaining);
      if (animation && animation.remaining > 1) {
        //animation.pause();
        //animation.seek(animation.duration);
        await animation.finished;
      }
      //console.log("animation done", animation?.remaining);
      animation = animate({
        targets: sidebarRef,
        translateX: translateX + "%",
        easing: "spring(1, 100, 6, 0)",
      });
      //      console.log("animation", animation);
      await animation.finished;
      //    console.log("animation finished", animation);
      animation = undefined;
    }
  };

  utils.onMount(() => {
    document.addEventListener("mousemove", handleMouseMove);
  });

  utils.onUnmount(() => {
    document.removeEventListener("mousemove", handleMouseMove);
  });

  utils
    .wire(($) => {
      const expanded = $expanded.get($); // subscribe to changes
      //console.log("wire", { direction, expanded });
      // trigger animation when expanded changes
    })
    .run();

  const mouseEvent = debounce((state: boolean) => {
    if ($docked?.get()) {
      $expanded.set(true);
      return;
    }
    if (state === false) {
      $expanded.set(state);
    } else if (state == true) {
      $expanded.set(state);
    }
  }, 100);

  return {
    mouseEvent,
    $expanded,
    handleMouseMove,
    sidebarRef: (el: HTMLElement) => {
      sidebarRef = el;
      // handleSlideAnimation();
    },
  };
};
