import { IBus, IBusAdapter } from "@gratico/sdk";

export function createBus(adapters: { [kind: string]: IBusAdapter }): IBus {
  // todo boot the adapters
  const bus = {
    adapters,
    peers: new Map(),
    channels: new Map(),
    state: {},
    pendingRequests: new Map<string, Promise<any>>(),
  };
  return bus;
}

export function addPeer(bus: IBus, id: string, kind: string, params: any) {
  const adapter = bus.adapters[kind];
  if (!adapter) return new Error("unable to find a adapter for kind " + kind);

  const peer = {
    id,
    type: kind,
    meta: {},
    // todo this should be proxy or something to adapter
    api: null,
  };
  bus.peers.set(id, peer);
  return peer;
}

export function removePeer(bus: IBus, id: string, kind: string, params: any) {
  bus.peers.delete(id);
}

export function sendMessage(bus: IBus, peerId: string, msg: any) {
  const id = crypto.randomUUID();
  const peer = bus.peers.get(peerId);
  if (!peer) throw new Error("peer not found " + peerId);
  const channel = bus.channels.get(peer.type);
  if (!channel)
    throw new Error(
      "channel not found " + peerId + " for adapter " + peer.type
    );

  channel.postMessage({ type: "call", id, msg });

  const promise = new Promise(function (resolve, reject) {});
  bus.pendingRequests.set(id, promise);
  return promise;
}

//
