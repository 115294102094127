/** @jsx h **/
import {
  IBranchChange,
  IBranchChanges,
  IBufferType,
  IFileBuferRouteParams,
  IFileBuffer,
  IMasterKernelAPI,
  IViewportKernel,
} from "@gratico/sdk";
import { Fragment, Signal, When, component, h } from "alfama";
import { Buffer } from "@gratico/fs";
import type { ReadOnlyEditor } from "@gratico/codemirror";
import { commitChanges } from "./utils";
import { Loading } from "@gratico/uikit";

export type Props = {
  master: IMasterKernelAPI;
  kernel: IViewportKernel;
  changes: IBranchChanges;
  $selectedFile: Signal<string | undefined>;
  $selectedFiles: Signal<string[]>;
  checkoutParamters: { projectId: string; ref: string };
};
export const FilesList = component<Props>("FilesList", (props, { wire }) => {
  return (
    <div class="flex flex-col flex-1 overflow-auto">
      <div class="px-4 py-2 flex justify-between items-center ">
        <span class="font-bold">
          Changes{" "}
          <span class="indicator-item badge badge-secondary">
            {props.changes.length}+
          </span>
        </span>
      </div>
      <div class="px-4 py-1 rounded">
        {props.changes.map((change, i) => {
          return (
            <div
              key={change.path}
              class={wire(($) => {
                const selectedFile = props.$selectedFile.get($);
                let base = "flex items-center p-2 ";
                base =
                  base +
                  (selectedFile && selectedFile === change.path
                    ? " bg-neutral-900"
                    : "hover:bg-neutral-800");
                return base;
              })}
            >
              <input
                checked={
                  wire(($) => {
                    const selectedFiles = props.$selectedFiles.get($);
                    const checked = selectedFiles.includes(change.path);
                    return checked ? true : undefined;
                  }) as any
                }
                onChange={(e) => {
                  const set = new Set(props.$selectedFiles.get());
                  if (e.currentTarget.checked) {
                    set.add(change.path);
                  } else {
                    set.delete(change.path);
                  }
                  props.$selectedFiles.set(Array.from(set));
                }}
                type="checkbox"
                class="checkbox mr-2"
              />
              <span
                onClick={(e) => {
                  props.$selectedFile.set(change.path);
                }}
                class="truncate cursor-pointer flex-1 truncate"
              >
                {change.path.slice(1)}
              </span>
              <button class="ml-auto"></button>
            </div>
          );
        })}
      </div>
    </div>
  );
});

export const FilePreview = component<Props>(
  "FilePreview",
  (props, { signal, onMount, wire }) => {
    const $contents = signal<
      | undefined
      | {
          contents: number[];
          original: number[] | undefined;
          ReadOnlyEditor?: typeof ReadOnlyEditor;
        }
    >("contents", undefined);
    wire(async ($) => {
      const selectedFile = props.$selectedFile.get($);
      $contents.set(undefined);
      if (selectedFile) {
        const change = props.changes.find(
          (el) => el.path === selectedFile
        ) as IBranchChange;
        //console.log("change", props.changes, change);
        const bufferParams: IFileBuferRouteParams = {
          projectId: props.checkoutParamters.projectId,
          ref: props.checkoutParamters.ref,
          path: selectedFile,
          type: IBufferType.FILE,
          appName: "",
        };
        const contents = await props.master.getFile(
          bufferParams,
          change.isDirty
        );
        const original = await props.master.getOriginalFile(
          props.checkoutParamters.projectId,
          props.checkoutParamters.ref,
          selectedFile
        );
        //console.log("ccc", contents, original);
        const { ReadOnlyEditor } = await import(
          "../../../../../packages/platform/apps/codemirror/src/components/ReadOnlyEditor/index"
        );
        $contents.set({
          contents,
          original: original || undefined,
          ReadOnlyEditor,
        });
        //console.log("$contents", $contents.get());
      } else {
        $contents.set(undefined);
      }
    }).run();
    return (
      <Fragment>
        <When
          condition={($) => {
            const contents = $contents.get($);
            //console.log("contents", contents);
            return !!contents;
          }}
          views={{
            true: () => {
              const selectedFile = props.$selectedFile.get() as string;
              const {
                contents,
                original,
                ReadOnlyEditor: ReadOnlyEditorComponent,
              } = $contents.get() as {
                contents: number[];
                original: number[] | undefined;
                ReadOnlyEditor: typeof ReadOnlyEditor;
              };
              //console.log("selectedFile", selectedFile);
              // todo only render for textual data
              const toStr = (d: number[]) =>
                Buffer.from(new Uint8Array(d)).toString("utf8");
              return (
                <div class="w-full relative h-full overflow-auto">
                  <ReadOnlyEditorComponent
                    path={selectedFile}
                    text={toStr(contents)}
                    original={original ? toStr(original) : ""}
                  />
                </div>
              );
            },
            false: () => {
              return (
                <div class="relative w-full self-baseline">
                  <Loading />
                </div>
              );
            },
          }}
        />
      </Fragment>
    );
  }
);

export const CommitForm = component<Props & { onCommit: Function }>(
  "CommitForm",
  (props, { signal, onMount, wire }) => {
    const $msg = signal("msg", "");
    const $loading = signal("loading", false);

    return (
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          const formData = new FormData(e.currentTarget);
          const json = Object.fromEntries(formData) as {
            message: string;
            description: string;
          };
          //console.log("json", json);
          const changes = props.changes;
          $loading.set(true);
          await commitChanges(
            props.checkoutParamters.projectId,
            props.checkoutParamters.ref,
            changes,
            json.message,
            json.description
          );
          $loading.set(false);
        }}
        class="grid grid-cols-1 gap-4 px-4 py-3 border-t"
      >
        <div class="form-control">
          <input
            class="input input-bordered  "
            autoFocus={true}
            autocomplete="off"
            name="message"
            type="text"
            pattern=".{1,}"
            placeholder="message"
            required={true}
            value={wire($msg.get)}
            onInput={(e) => $msg.set(e.currentTarget.value)}
          />
        </div>

        <div class="form-control">
          <textarea
            class="textarea textarea-bordered textarea-sm w-full "
            name="description"
            placeholder="description"
            rows={1}
          ></textarea>
        </div>

        <div class="form-control">
          <button
            disabled={
              wire(($) => {
                const selectedFiles = props.$selectedFiles.get($);
                const msg = $msg.get($);
                const loading = $loading.get($);
                return selectedFiles.length === 0 || !msg || loading
                  ? true
                  : undefined;
              }) as any
            }
            class="button primary text-3xl py-3"
          >
            <When
              condition={$loading.get}
              views={{
                true: () => (
                  <span
                    style="width: 2.25rem;"
                    class="loading loading-spinner loading-lg"
                  ></span>
                ),
                false: () => (
                  <Fragment>
                    <i class="ri-git-commit-line"></i> Commit
                  </Fragment>
                ),
              }}
            />
          </button>
        </div>
      </form>
    );
  }
);
