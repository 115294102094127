import { HydraKeyMap, HydraKeyMapType, IEditorAction } from "@gratico/sdk";
import {
  deleteBufferCommand,
  nextBufferCommand,
  previousBufferCommand,
} from "@gratico/kernel";

export const buffersKeyMap: HydraKeyMap[] = [
  {
    id: "buffers",
    parentId: "leader",
    type: HydraKeyMapType.GROUP,
    key: "b",
    name: "Buffers",
  },
  {
    id: "nextBuffer",
    parentId: "buffers",
    type: HydraKeyMapType.ACTION,
    key: "n",
    name: "Next Buffer",
    action: nextBufferCommand,
  },
  {
    id: "previousBuffer",
    parentId: "buffers",
    type: HydraKeyMapType.ACTION,
    key: "p",
    name: "Previous Buffer",
    action: previousBufferCommand,
  },
  {
    id: "deleteBuffer",
    parentId: "buffers",
    type: HydraKeyMapType.ACTION,
    key: "d",
    name: "Delete Buffer",
    action: deleteBufferCommand,
  },
];
