export function getHubHost() {
  const envName = import.meta.env.VITE_ENV;
  const host = (function () {
    if (envName === "development") {
      return `http://localhost:5100`;
    } else if (envName === "preview") {
      return `https://${import.meta.env.CF_PAGES_BRANCH.replaceAll(
        "/",
        "-"
      )}.gratico-hub.pages.dev`;
    } else {
      return `https://www.grati.co`;
    }
  })();
  return host;
}
