/** @jsx h **/

import { component, When, h, Signal } from "alfama";

export const Loading = component<{ class?: string; style?: string }>(
  "SVGLoader",
  (props) => {
    return (
      <progress
        style={"height: 2px; position: absolute;top:0;" + (props.style || "")}
        class={"progress w-full my-0 " + (props.class || "")}
      ></progress>
    );
  }
);
