/** @jsx h **/
import { CommandItem, MultiStepItem, MultiStepItemType } from "@gratico/sdk";
import { match } from "ts-pattern";

export const createFunctionCodeCommand: CommandItem = {
  type: MultiStepItemType.COMMAND,
  id: "CreateFunction",
  label: "Create Function",
  icon: "ri-formula",
  action: {
    name: "Create Function",
    inputs: (selections) => {
      console.log("createFunctionCommand", selections);
      const output = match(selections)
        .with([{ type: MultiStepItemType.COMMAND }], () => "command")
        .with([], () => "empty")
        .otherwise(() => "");
      return {
        name: "file",
        type: MultiStepItemType.FOLDER,
      };
    },
    async perform(props, attrs) {},
  },
};

export const codeCommands = [createFunctionCodeCommand];
