/** @jsx h **/
import { CommandItem, MultiStepItem, MultiStepItemType } from "@gratico/sdk";

export const nextBufferCommand: CommandItem = {
  type: MultiStepItemType.COMMAND,
  id: "nextBuffer",
  label: "Next Buffer",
  action: {
    name: "Next Buffer",
    inputs: () => undefined,
    async perform(ctx, attrs) {
      return null;
    },
  },
};
export const previousBufferCommand: CommandItem = {
  type: MultiStepItemType.COMMAND,
  id: "previousBuffer",
  label: "Previous Buffer",
  action: {
    name: "Previous Buffer",
    inputs: () => undefined,
    async perform(ctx, attrs) {
      return null;
    },
  },
};
export const deleteBufferCommand: CommandItem = {
  type: MultiStepItemType.COMMAND,
  id: "deleteBuffer",
  label: "Delete Buffer",
  action: {
    name: "Delete Buffer",
    inputs: () => undefined,
    async perform(ctx, attrs) {
      return null;
    },
  },
};

export const bufferCommands = [
  nextBufferCommand,
  previousBufferCommand,
  deleteBufferCommand,
];
