import { IBaseKernel, IBufferRouteParams, IBufferType } from "@gratico/sdk";
import { extractParameters } from "alfama-router";
import { BufferRoutePattern } from "@gratico/sdk";

export function getCurrentProjectParameters(
  kernel: IBaseKernel,
  location: Location
) {
  const params = extractParameters<Partial<IBufferRouteParams>>(
    BufferRoutePattern,
    location.pathname
  );
  if (params?.projectId) {
    return { projectId: params.projectId, ref: params.ref || "master" };
  } else {
    return { projectId: kernel.params.session.project.id, ref: "master" };
  }
}
