import { HydraKeyMap, HydraKeyMapType, IEditorAction } from "@gratico/sdk";
import { pullGitCommand } from "@gratico/kernel";

export const filesKeyMap: HydraKeyMap[] = [
  {
    id: "files",
    parentId: "leader",
    type: HydraKeyMapType.GROUP,
    key: "f",
    name: "Files",
  },
  {
    id: "createFile",
    parentId: "files",
    type: HydraKeyMapType.ACTION,
    key: "c",
    name: "Create File",
    action: pullGitCommand,
  },
];
