import { StreamMessage, Tool, StreamingMessage } from "../types";
import type { ChatCompletionChunk } from "@gratico/chat";
import {
  IChatMessage,
  IAssistantMessage,
  IChatMessageType,
} from "@gratico/sdk";

class MessageAccumulator<T = StreamMessage> {
  private accumulator: string = "";

  process(chunk: string): T[] {
    this.accumulator += chunk;
    const messages: T[] = [];
    let dataIndex: number;

    while ((dataIndex = this.accumulator.indexOf("data: ")) !== -1) {
      // Remove any content before 'data: '
      this.accumulator = this.accumulator.slice(dataIndex);

      const endIndex = this.accumulator.indexOf("\n");
      if (endIndex === -1) break; // Incomplete message, wait for more data

      const messageStr = this.accumulator.slice(6, endIndex).trim();
      this.accumulator = this.accumulator.slice(endIndex + 1);
      if (messageStr !== "[DONE]") {
        try {
          const message: T = JSON.parse(messageStr);
          messages.push(message);
        } catch (error) {
          console.error("Error parsing message:", error);
        }
      }
    }

    return messages;
  }
}

const makeRequest = async (
  model: string,
  payload: { messages: IChatMessage[]; tools: any[] }
) => {
  const controller = new AbortController();
  const response = await fetch(
    "/~/api/v1/chat/llm/completions?model=" + model,
    {
      signal: controller.signal,
      method: "POST",
      headers: {
        "Content-Type": "text/event-stream",
      },
      body: JSON.stringify(payload),
    }
  );
  const reader = (response.body as ReadableStream)
    .pipeThrough(new TextDecoderStream())
    .getReader();
  return { reader, controller };
};

export const runAndProcessToolCall = async (
  messages: IChatMessage[],
  tools: Tool[],
  options: {
    onToken?: (str: string) => void;
    onComplete?: (text: string) => void;
    model: string;
  }
) => {
  const accumulator = new MessageAccumulator<ChatCompletionChunk>();
  const responses: string[] = [];
  const processStream = async (
    reader: ReadableStreamDefaultReader<string>,
    controller: AbortController,
    promise: any,
    state: StreamingMessage = { type: "string", tokens: [] }
  ) => {
    function processStreamMessage({
      value,
      done,
    }: ReadableStreamReadResult<string>): any {
      //console.log("value", value);
      if (done) {
        //console.log("Stream complete");
        return;
      }

      const chunk = value;
      const messages = accumulator.process(chunk);

      messages.forEach((chunk: ChatCompletionChunk) => {
        // Dispatch the message here
        const str = chunk.choices[0].delta.content;
        if (str) {
          //console.log("Dispatching message:", chunk, str);
          responses.push(str);
          options.onToken && options.onToken(str);
        }
      });
      return reader.read().then(processStreamMessage as any);
    }

    return reader
      .read()
      .then(processStreamMessage as any)
      .then(() => {
        console.log("finished");
        const text = responses.join("");
        options.onComplete && options.onComplete(text);
      });
  };

  return new Promise(async (resolve, reject) => {
    const { reader, controller } = await makeRequest(options.model, {
      messages,
      tools: tools.map((el) => el.advt),
    });
    return processStream(reader, controller, { resolve, reject });
  });
};
