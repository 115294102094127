import { IViewportKernel } from "@gratico/sdk";
import { ES_MODULE_SHIM_URL } from "@gratico/packager";

const getRuntimeJS = (mods: string[]) => {
  return `
globalThis.boot = async function (esmsInitOptions, logger) {
  globalThis["_gcf"] = logger;
  globalThis.esmsInitOptions = esmsInitOptions
  await import("${ES_MODULE_SHIM_URL}");
  const importShim = globalThis.importShim;
  return {importShim };
}
`;
};

export async function getSandboxHTML(
  kernel: IViewportKernel,
  params: { projectId: string; ref: string }
) {
  return `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>My App</title>
    <script type="module">${getRuntimeJS([])}</script>
  </head>
  <body>
    <div id="app"></div>
  </body>
</html>

  `;
}
