/** @jsx h **/

import { component, When, h, Signal } from "alfama";
import { BrowserRouter, RouterContext } from "alfama-router";
import { ILoggedinSession, IUserSession } from "@gratico/kernel";
import { KernelConnection, ModalsProviders } from "../../providers/index";
import { fetchSession, bc, connectToMaster } from "../../utils/boot";
import { BootedLayout, UnbootedLayout } from "../../components/index";
import { BuffersProvider } from "../../providers/index";

export const LayoutInner = component<{}>(
  "Layout",
  (props, { signal, onMount, getContext, onUnmount }) => {
    const $session = signal<IUserSession | undefined>("session", undefined);
    const $kernelConnection = signal<KernelConnection | undefined>(
      "kernelConnection",
      undefined
    );
    const router = getContext(RouterContext).get();

    onMount(() => {
      //console.log("mountedd");
      const connection = $kernelConnection.get();
      if (connection) return;
      if ($session.get()) return;
      bc.postMessage({ message: "fetching session" });
      const { host } = window.location;
      const session = fetchSession(host)
        .then(async (session) => {
          console.log(session);
          if (!session || !session.loggedIn) {
            window.location.href = `/~/api/v1/auth/session/initiate?href=${encodeURIComponent(
              window.location.pathname
            )}&host=${encodeURIComponent(window.location.host)}`;
          } else {
            if (session.loggedIn) {
              $session.set(session);
              try {
                console.time("connectToMaster");
                const connection = await connectToMaster(router, {
                  session,
                });
                console.timeEnd("connectToMaster");
                $kernelConnection.set({
                  kernel: connection.kernel,
                  master: connection.master,
                  viewport: connection.viewport,
                });
              } catch (err: any) {
                console.error(err);
                alert(`Error occured ${err?.message}`);
              }
            }
          }
        })
        .catch((err) => {
          console.error(err);
          const reload = confirm(
            `Something went wrong while fetching your session! ${err.message}! Reload?`
          );
          if (reload) {
            window.location.reload();
          }
        });
    });
    onUnmount(async () => {
      const connection = $kernelConnection.get();
      if (connection) {
      }
    });
    return (
      <When
        condition={($) => {
          return !!$kernelConnection.get($);
        }}
        views={{
          true: () => {
            return (
              <BootedLayout
                $connection={$kernelConnection as Signal<KernelConnection>}
                $session={$session as Signal<ILoggedinSession>}
              />
            );
          },
          false: () => {
            return <UnbootedLayout />;
          },
        }}
      ></When>
    );
  }
);

// https://gratico.canny.io/feature-requests
export const Layout = component<{}>(
  "Layout",
  (props, { signal, onMount, getContext }) => {
    return (
      <BrowserRouter>
        <ModalsProviders>
          <BuffersProvider>
            <LayoutInner />
          </BuffersProvider>
        </ModalsProviders>
      </BrowserRouter>
    );
  }
);
